import { API_SERVER } from '../utils/constants';

export async function getKioskDetails() {
	const response = await fetch(`${API_SERVER}getkioskdetails`);
	const body = await response.json();
	if (response.status !== 200) throw Error(body.error);
	return body;
}

export async function getFromApi(url) {
	const response = await fetch(API_SERVER + url);
	const body = await response.json();
	if (response.status !== 200) throw Error(body.error);
	return body;
}

export async function getNews(region) {
	// const response = await fetch(`${API_SERVER}news/getnews`);
	const response = await fetch(`${API_SERVER}news/getpagenews/${region}`);
	const body = await response.json();
	if (response.status !== 200) throw Error(body.error);
	return body;
}

export async function getNewsSettings(region) {
	const response = await fetch(`${API_SERVER}news/getnewssettings/${region}`);
	const body = await response.json();
	return body;
}

export async function getWeatherForecast() {
	const response = await fetch(`${API_SERVER}getweatherforecast`);
	const body = await response.json();
	if (response.status !== 200) throw Error(body.error);
	return body;
}

export async function getFailteireActivities() {
	const response = await fetch(`${API_SERVER}getfailteireactivities`);
	const body = await response.json();
	if (response.status !== 200) throw Error(body.error);
	return body;
}

export async function getFailteireAttractions() {
	const response = await fetch(`${API_SERVER}getfailteireattractions`);
	const body = await response.json();
	if (response.status !== 200) throw Error(body.error);
	return body;
}
