import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ErrorAlert from '../../reuseable/ErrorAlert';
import { API_SERVER } from '../../../utils/constants';
import PasswordCalculator from '../../PasswordCalculator';
import body from '../../../assets/build-code.json';
import { MainHeading } from '../../reuseable/Headings';
import LargeLoading from '../../reuseable/loading/LargeLoading';

const HeadingContentSpacing = styled.p`
  margin-bottom: 3em;
`;

class CommissionNewKiosk extends Component {
	constructor(props) {
		super(props);

		this.state = {
			commissionNumber: '',
			wait: false,
			errMessage: '',
		};

		this.commission = this.commission.bind(this);
		this.doCommission = this.doCommission.bind(this);
	}

	// clear db and rebuild tables
	initialise = async () => {
		const response = await fetch(`${API_SERVER}initialise`);
		const jsonBody = await response.json();
		return jsonBody;
	}

	// save kiosk details to db
	commission = async () => {
		const { commissionNumber } = this.state;
		const response = await fetch(`${API_SERVER}commissionkiosk/${commissionNumber}`);
		const jsonBody = await response.json();
		return jsonBody;
	}

	// uses the kiosk details to pull all the relevant POI,News,Council Info etc...
	initialiseData = async () => {
		let includeWeather = true;
		let includeAir = true;
		let includeNews = true;

		if (localStorage.getItem('json_structure') !== null && localStorage.getItem('json_structure') !== '') {
			const json = JSON.parse(localStorage.getItem('json_structure'));
			if (Object.keys(json).length > 0 && json.hasOwnProperty('modules')) {
				const paramsWeather = _.filter(json.modules, { name: 'WeatherForecast' });
				const paramsAir = _.filter(json.modules, { name: 'AirQuality' });
				const paramsNews = _.filter(json.modules, (item) => (item.component === 'NewsFeedBanner' || item.component === 'NewsFeedPage' || item.name === 'BbcNewsFull'));

				if (paramsWeather.length === 0) { includeWeather = false; }
				if (paramsAir.length === 0) { includeAir = false; }
				if (paramsNews.length === 0) { includeNews = false; }
			}
		}

		const payload = {
			getWeather: includeWeather,
			getAirQuality: includeAir,
			getNews: includeNews,
		};

		const response = await fetch(`${API_SERVER}initialiseData`, {
			method: 'POST',
			body: JSON.stringify(payload),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		});
		const jsonBody = await response.json();
		return jsonBody;
	}

	doCommission(commissionNumber) {
		const { stateUpdate } = this.props;
		// commissioning IDs should all be 4 or more characters
		if (commissionNumber > 999) {
			const propState = { kioskDetails: {}, position: {} };
			const localState = { wait: false };
			this.setState({ wait: true, commissionNumber, errMessage: '' }, () => {
				// clear db and rebuild tables
				this.initialise()
					.then((response) => response)
					// save kiosk details to db
					.then(() => this.commission())
					.then((res) => {
						if (res.result === true) {
							// updating the json structure
							if (res.kioskDetails.hasOwnProperty('json_structure')) {
								let jsonStructure = JSON.stringify(body);
								if (res.kioskDetails.json_structure.length > 0) {
									jsonStructure = res.kioskDetails.json_structure;
								}
								if (jsonStructure !== localStorage.getItem('json_structure')) {
									localStorage.setItem('json_structure', jsonStructure);
								}
							}

							propState.kioskDetails = res.kioskDetails;
							propState.position = {
								lat: parseFloat(res.kioskDetails.lat),
								lng: parseFloat(res.kioskDetails.lon),
							};
							return this.initialiseData();
						}

						localState.errMessage = 'Kiosk Not Found';
						return res.result;
					})
					.then((res) => res)
					.catch(() => {
						// console.log(err)
					})
					.finally(() => {
						stateUpdate(propState);
						this.setState(localState);
					});
			});
		}
	}

	render() {
		const { errMessage, wait } = this.state;
		return (
			<div className="commissionNewContainer col-sm-12 no-padding">
				{errMessage.length > 0 && <ErrorAlert>{errMessage}</ErrorAlert>}
				{
					(wait) ? (
						<LargeLoading text="Kiosk is commissioning" />
					)
						: (
							<>
								<MainHeading className="text-center" id="newCommission">Commission With Kiosk ID</MainHeading>
								<p className="text-center">This machine has not been commissioned yet. Use the keypad below to enter the kiosk ID.</p>
								<HeadingContentSpacing className="text-center">{'When you have done this, press the green \'Enter\' button to start the commissioning process.'}</HeadingContentSpacing>
								<PasswordCalculator
									userCode={this.doCommission}
									passwordSuccess={false}
									passwordFail={false}
									showNumber
								/>
							</>
						)
				}
			</div>
		);
	}
}

export default CommissionNewKiosk;

CommissionNewKiosk.propTypes = {
	stateUpdate: PropTypes.func.isRequired,
};
