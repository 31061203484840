import React from 'react';
import PropTypes from 'prop-types';
import Table from '../Table';

/**
 * Child component to show kiosk table
 */
function KioskRightTableDetails(props) {
	const { classes, data, headers } = props;
	return (
		<Table classes={`kiosk-details ${classes}`} data={data} headers={headers} />
	);
}

export default KioskRightTableDetails;

KioskRightTableDetails.defaultProps = {
	classes: '',
	headers: [],
};

KioskRightTableDetails.propTypes = {
	classes: PropTypes.string,
	data: PropTypes.arrayOf(PropTypes.array).isRequired,
	headers: PropTypes.arrayOf(PropTypes.array),
};
