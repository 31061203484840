import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Table from '../Table';
import WebIcon from '../icons/WebIcon';

/**
 * Child component to show air quality table
 */
class AirQualityWidget extends Component {
	static newMarker() {
		return (
			<>
				<WebIcon icon="asterisk" />
				<br />
				<WebIcon icon="circle" className="active" />
			</>
		);
	}

	render() {
		const { level } = this.props;
		// 10 default values
		const airQuality = [
			{ value: <WebIcon icon="circle" /> },
			{ value: <WebIcon icon="circle" /> },
			{ value: <WebIcon icon="circle" /> },
			{ value: <WebIcon icon="circle" /> },
			{ value: <WebIcon icon="circle" /> },
			{ value: <WebIcon icon="circle" /> },
			{ value: <WebIcon icon="circle" /> },
			{ value: <WebIcon icon="circle" /> },
			{ value: <WebIcon icon="circle" /> },
			{ value: <WebIcon icon="circle" /> },
		];
		// update selected
		if (level) {
			airQuality[(level - 1)].value = AirQualityWidget.newMarker();
		}

		return (
			<Table
				id="airQualityWidget"
				classes="airQualityWidget_dots"
				data={[
					airQuality,
					[
						{ colspan: 3, value: <b><FormattedMessage id="header.airquality.good" defaultMessage="Good" /></b>, classes: 'text-left' },
						{ colspan: 4, value: <b><FormattedMessage id="header.airquality.title" defaultMessage="Air Quality" /></b>, classes: 'text-center' },
						{ colspan: 3, value: <b><FormattedMessage id="header.airquality.bad" defaultMessage="Bad" /></b>, classes: 'text-right' },
					],
				]}
			/>
		);
	}
}

export default AirQualityWidget;

AirQualityWidget.defaultProps = {
	level: 0,
};

AirQualityWidget.propTypes = {
	level: PropTypes.number,
};
