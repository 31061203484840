import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

/**
 * Child button, use for successful button
 */
function SuccessButton(props) {
	const {
		children,
		className,
		handleClick,
		id,
		link,
	} = props;
	return (
		<Button variant="success" id={id} className={className} handleClick={handleClick} link={link}>{children}</Button>
	);
}

export default SuccessButton;

SuccessButton.defaultProps = {
	className: '',
	id: '',
	link: '',
};

SuccessButton.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	handleClick: PropTypes.func.isRequired,
	id: PropTypes.string,
	link: PropTypes.string,
};
