import React from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

/**
 * Master component for layouts
 */
function Grid(props) {
	const {
		children,
		classes,
		className,
		desktop,
		hideDesktop,
		hideMobile,
		hideTablet,
		id,
		mobile,
		tablet,
	} = props;

	const newProps = { id };
	if (classes || className) {
		newProps.className = `${classes} ${className}`;
	}
	if (mobile) {
		newProps.xs = mobile;
	}
	if (tablet) {
		newProps.sm = tablet;
	}
	if (desktop) {
		newProps.md = desktop;
	}
	if (hideMobile) {
		newProps.xsHidden = hideMobile;
	}
	if (hideTablet) {
		newProps.smHidden = hideTablet;
	}
	if (hideDesktop) {
		newProps.mdHidden = hideDesktop;
	}

	return (
		<Col {...newProps}>{children}</Col>
	);
}

export default Grid;

Grid.defaultProps = {
	classes: '',
	className: '',
	id: '',
	mobile: 12,
	tablet: 0,
	desktop: 0,
	hideMobile: false,
	hideTablet: false,
	hideDesktop: false,
};

Grid.propTypes = {
	children: PropTypes.node.isRequired,
	classes: PropTypes.string,
	className: PropTypes.string,
	id: PropTypes.string,

	mobile: PropTypes.number,
	tablet: PropTypes.number,
	desktop: PropTypes.number,

	hideMobile: PropTypes.bool,
	hideTablet: PropTypes.bool,
	hideDesktop: PropTypes.bool,
};
