import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import LandingPage from './components/pages/LandingPage';
import TermsConditions from './components/pages/TermsConditions';
import Template from './components/pages/Template';
import Commission from './components/pages/commission/Commission';
import NotFound from './components/pages/NotFound';
import AccessibilityContext from './hooks/accessibility-context';

const body = (localStorage.getItem('json_structure') === null || localStorage.getItem('json_structure').length === 0) ? {} : JSON.parse(localStorage.getItem('json_structure'));

export function getComponentByName(name) {
	switch (name) {
	case 'LandingPage':
		return LandingPage;
	case 'TermsConditions':
		return TermsConditions;
	default:
		return Template;
	}
}

class Root extends Component {
	constructor(props) {
		super(props);

		this.updateLanguage = this.updateLanguage.bind(this);
	}

	updateLanguage(lang) {
		const { updateLanguage } = this.props;
		updateLanguage(lang);
	}

	render() {
		const { language } = this.props;
		return (
			<AccessibilityContext.Consumer>
				{(context) => {
					const wheelchairAccessClass = (context.wheelchair) ? 'wheelchair-access' : '';
					return (
						<div className={`content-container ${wheelchairAccessClass}`}>
							<Switch>
								{
									_.map(body.routes, (nav, index) => {
										const DynamicComponent = getComponentByName(nav.page);
										// const page = _.filter(body.pages, { name: nav.page });
										return (
											<Route
												key={index}
												exact={nav.exact}
												path={nav.path}
												render={(props) => (
													<DynamicComponent
														{...props}
														pageName={nav.page}
														updateLanguage={this.updateLanguage}
														language={language}
													/>
												)}
											/>
										);
									})
								}
								<Route exact path="/commission" component={Commission} />
								<Route component={NotFound} />
							</Switch>
						</div>
					);
				}}
			</AccessibilityContext.Consumer>
		);
	}
}

export default Root;

Root.propTypes = {
	language: PropTypes.string.isRequired,
	updateLanguage: PropTypes.func.isRequired,
};
