import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button as BootstrapButton } from 'react-bootstrap';
import PropTypes from 'prop-types';

/**
 * Master button component, use for extension
 */
function Button(props) {
	const {
		children,
		classes,
		className,
		handleClick,
		history,
		id,
		link,
		variant,
	} = props;
	const newProps = {
		id,
		className: `default-button ${classes} ${className}`,
		bsStyle: variant,
	};

	if (handleClick) {
		newProps.onClick = () => handleClick();
	}

	if (link) {
		newProps.onClick = () => history.push(link);
	}

	/**
	 * Renders the component
	 */
	return (
		<BootstrapButton {...newProps}>{children}</BootstrapButton>
	);
}

export default withRouter(Button);

Button.defaultProps = {
	id: '',
	classes: '',
	className: '',
	history: {},
	link: '',
	variant: 'info',
};
Button.propTypes = {
	id: PropTypes.string,
	classes: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	handleClick: PropTypes.func.isRequired,
	history: PropTypes.shape({ push: PropTypes.func }),
	link: PropTypes.string,
	variant: PropTypes.string,
};
