export function createMap(google, elementId, zoom, defaultCenter, boundary, mapOptions) {
	return new google.maps.Map(document.getElementById(elementId), {
		zoom,
		center: defaultCenter,
		restriction: {
			latLngBounds: boundary,
			strictBounds: false,
		},
		// disableDefaultUI: true,
		options: mapOptions,
	});
}

export function createMapWithBoundary(google, elementId, zoom, defaultCenter, mapOptions) {
	const boundary = {
		north: defaultCenter.lat + 0.016,
		south: defaultCenter.lat - 0.016,
		east: defaultCenter.lng + 0.032,
		west: defaultCenter.lng - 0.032,
	};
	return createMap(google, elementId, zoom, defaultCenter, boundary, mapOptions);
}

export function addInfoWindow(google, map, marker, placeId, link, name) {
	const infowindow = new google.maps.InfoWindow({
		content: `<span class="InfoWindowPlaceLink" data-placeid="${placeId}" data-url="${link}" id="content">${name}<br />Click here for directions &gt;</span>`,
	});
	infowindow.open(map, marker); // add info window to map marker
}

export function addCustomMarker(google, map, item) {
	const pinSVGFilled = 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z';
	const labelOriginFilled = new google.maps.Point(12, 9);
	const markerImage = {
		path: pinSVGFilled,
		anchor: new google.maps.Point(12, 17),
		fillOpacity: 1,
		fillColor: '#7d528e',
		strokeWeight: 2,
		strokeColor: 'white',
		scale: 2,
		labelOrigin: labelOriginFilled,
	};
	const marker = new google.maps.Marker({
		position: { lat: item.lat, lng: item.lng },
		map,
		icon: markerImage,
		animation: google.maps.Animation.DROP,
	});

	addInfoWindow(google, map, marker, item.placeId, item.link, item.name);
}

export function addMarker(google, map, position, icon, label, drag) {
	const properties = {
		position, map, icon, animation: google.maps.Animation.DROP,
	};
	if (typeof label === 'string') {
		properties.label = label;
	}
	if (typeof icon === 'undefined') {
		delete properties.icon;
	}
	if (typeof drag === 'boolean') {
		properties.draggable = drag;
	}
	return new google.maps.Marker(properties);
}

export function addLabelMarker(google, map, position, label) {
	return addMarker(google, map, position, '/images/map-marker.png', label);
}

export function addCircle(google, map, center, radius, colour) {
	return new google.maps.Circle({
		strokeColor: colour,
		strokeOpacity: 0.8,
		strokeWeight: 2,
		fillOpacity: 0,
		map,
		center,
		radius,
	});
}

export function addDirection(google, map, from, to) {
	const directionsService = new google.maps.DirectionsService();
	const directionsDisplay = new google.maps.DirectionsRenderer();
	directionsDisplay.setMap(map);
	directionsService.route({
		origin: from,
		destination: to,
		travelMode: 'WALKING',
	}, (response, status) => {
		if (status === 'OK') {
			directionsDisplay.setDirections(response);
		}
	});
}
