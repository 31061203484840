import React from 'react';
import { Link } from 'react-router-dom';
import ComponentContainer from '../reuseable/layouts/ComponentContainer';
import WebIcon from '../reuseable/icons/WebIcon';

function NotFound() {
	return (
		<ComponentContainer id="NotFound" width="100" height="100" top={0} left={0}>
			<div className="white-container absolute">
				<div className="display-table">
					<div className="display-cell text-center">
						<Link to="/">
							<h1>
								<WebIcon icon="warning-circle" />
								<br />
								{'Something Went Wrong'}
							</h1>
							<p>
								{'The page you were looking for could not be found'}
								<br />
								{'click here to go back to the main screen'}
							</p>
						</Link>
					</div>
				</div>
			</div>
		</ComponentContainer>
	);
}

export default NotFound;
