import React from 'react';
import PropTypes from 'prop-types';
import H1 from './headings/H1';
import H2 from './headings/H2';
import H4 from './headings/H4';

/**
 * Child components for individal headings
 */
export function TopHeading(props) {
	const { children, className, id } = props;
	return (<H1 id={id} className={`page-heading text-center ${className}`}>{children}</H1>);
}

export function MainHeading(props) {
	const { children, className, id } = props;
	return (<H2 id={id} className={`page-heading text-center ${className}`}>{children}</H2>);
}

export function SubHeading(props) {
	const { children, className, id } = props;
	return (<H2 id={id} className={className}>{children}</H2>);
}

export function InlineHeading(props) {
	const { children, className, id } = props;
	return (<H4 id={id} className={className}>{children}</H4>);
}

TopHeading.defaultProps = {
	id: '',
	className: '',
};
TopHeading.propTypes = {
	id: PropTypes.string,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

MainHeading.defaultProps = {
	id: '',
	className: '',
};
MainHeading.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	id: PropTypes.string,
};

SubHeading.defaultProps = {
	id: '',
	className: '',
};
SubHeading.propTypes = {
	id: PropTypes.string,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

InlineHeading.defaultProps = {
	id: '',
	className: '',
};
InlineHeading.propTypes = {
	id: PropTypes.string,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
