import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Grid from './Grid';
import { getFromApi } from '../../services/apiFunctions';

/**
 * Manages the grid layout for the footer toolbar
 */
function FooterLayout(props) {
	const [bgImage, setBgImage] = React.useState('');
	const {
		address, unitId, showText, text, color, bgColor, useImage, siteNo, countryName, oasisId,
	} = props;

	const getImageFromDb = async () => {
		try {
			const response = await getFromApi('getimage/footer');
			if (response.result === true) {
				setBgImage(response.details.base64);
			}
			return '';
		} catch (e) {
			// console.log(e);
			return '';
		}
	};

	React.useEffect(() => {
		// on load
		if (useImage === true) {
			getImageFromDb();
		}
	}, [useImage]);

	let UnitText = styled(Grid)`
	color: ${color};
	background-color: ${bgColor};
	`;
	let customContentClass = '';
	if (bgImage.length > 0) {
		UnitText = styled(Grid)`
			background-image: url(data:image/jpg;base64,${bgImage});
			text-indent:-9999px;
			background-size:cover;
			background-position:center;
		`;
		customContentClass = 'footer-background-image';
	}
	const gridSize = (showText === true) ? 4 : 6;
	const trimmedText = (typeof text === 'string') ? text.substring(0, 25) : ''; // have a max length that will fit in the space

	return (
		<footer className="primary-bg-color App-footer">
			<Grid tablet={gridSize} classes="unit-details text-center primary-bg-color" id="footer_address">{address}</Grid>
			{
				(showText === true)
					? <UnitText hideMobile tablet={gridSize} classes={`unit-details center text-center black-bg-color ${customContentClass}`} id="footer_type">{trimmedText}</UnitText>
					: false
			}
			<Grid hideMobile tablet={gridSize} classes="unit-details text-center primary-bg-color" id="footer_right">
				<FormattedMessage id="footer.siteid" defaultMessage="Site ID" />
				{': '}
				{
					(countryName === 'Ireland')
						? oasisId
						: siteNo || unitId
				}
			</Grid>
		</footer>
	);
}

export default FooterLayout;

FooterLayout.defaultProps = {
	address: '',
	unitId: '',
	showText: false,
	text: '',
	color: '',
	bgColor: '',
	siteNo: '',
	countryName: '',
	oasisId: '',
};

FooterLayout.propTypes = {
	address: PropTypes.string,
	unitId: PropTypes.string,
	siteNo: PropTypes.string,
	showText: PropTypes.bool,
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool,
	]),
	color: PropTypes.string,
	bgColor: PropTypes.string,
	useImage: PropTypes.bool.isRequired,
	countryName: PropTypes.string,
	oasisId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
