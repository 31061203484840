import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FooterLayout from '../reuseable/FooterLayout';

/**
 * Displays the footer
 */
class Footer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			unitId: '',
			address: '',
			useImage: false,
		};

		this.updateLayout = this.updateLayout.bind(this);
	}

	componentDidMount() {
		this.updateLayout();
	}

	componentDidUpdate(prevProps) {
		const { kioskDetails } = this.props;
		if (prevProps.kioskDetails !== kioskDetails) {
			this.updateLayout();
		}
	}

	/**
	 * Setup of layout
	 */
	updateLayout() {
		const { kioskDetails } = this.props;
		if (typeof (kioskDetails) === 'object') {
			const state = { unitId: '', address: '', siteNo: '' };
			const pcode = (kioskDetails.hasOwnProperty('postcode') && kioskDetails.postcode !== 'null' && kioskDetails.postcode !== null) ? `, ${kioskDetails.postcode}` : '';
			let addr1 = (kioskDetails.hasOwnProperty('pos') && kioskDetails.pos !== 'null' && kioskDetails.pos !== null) ? kioskDetails.pos : '';
			addr1 += (addr1.length > 0 && kioskDetails.hasOwnProperty('addr1') && kioskDetails.addr1 !== 'null' && kioskDetails.addr1 !== null) ? ', ' : '';
			addr1 += (kioskDetails.hasOwnProperty('addr1') && kioskDetails.addr1 !== 'null' && kioskDetails.addr1 !== null) ? kioskDetails.addr1 : '';
			state.unitId = (kioskDetails.hasOwnProperty('unit_id')) ? kioskDetails.unit_id : '';
			state.address = addr1 + pcode;
			state.siteNo = (kioskDetails.hasOwnProperty('site_no')) ? kioskDetails.site_no : '';
			state.countryName = (kioskDetails.hasOwnProperty('country_name')) ? kioskDetails.country_name : '';
			state.oasisId = (kioskDetails.hasOwnProperty('country_name')) ? kioskDetails.oasis_id : '';

			if (kioskDetails.hasOwnProperty('json_structure')) {
				const json = JSON.parse(kioskDetails.json_structure);
				if (json.hasOwnProperty('footer')) {
					state.text = json.footer.text;
					state.color = json.footer.textColor;
					state.bgColor = json.footer.bgColor;
					if (json.footer.hasOwnProperty('use_image')) {
						state.useImage = json.footer.use_image;
					}
				}
			}

			this.setState(state);
		}
	}

	/**
	 * Renders the footer layout
	 * @return {Component}
	 */
	render() {
		const {
			address, unitId, text, color, bgColor, useImage, siteNo, countryName, oasisId,
		} = this.state;
		const showText = ((typeof text === 'string' && text.length > 0) || useImage === true);
		const { kioskDetails } = this.props;
		return (
			<footer className="primary-bg-color App-footer">
				{ kioskDetails
					&& (
						<FooterLayout
							address={address}
							unitId={unitId}
							showText={showText}
							text={text}
							color={color}
							bgColor={bgColor}
							useImage={useImage}
							siteNo={siteNo}
							countryName={countryName}
							oasisId={oasisId}
						/>
					)}
			</footer>
		);
	}
}

export default Footer;

Footer.defaultProps = {
	kioskDetails: {},
};
Footer.propTypes = {
	kioskDetails: PropTypes.shape({
		addr1: PropTypes.string,
		site_no: PropTypes.string,
		country_name: PropTypes.string,
		base64: PropTypes.string,
		json_structure: PropTypes.string,
		pos: PropTypes.string,
		postcode: PropTypes.string,
		oasis_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		unit_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
};
