import React from 'react';
import PropTypes from 'prop-types';
import Loading from './Loading';
import WebIcon from '../icons/WebIcon';

/**
 * Child component, used as text loader
 */
function LargeLoading(props) {
	const { id, text } = props;
	return (
		<Loading id={id}>
			<h1>
				{'Please Wait'}
				<br />
				<WebIcon id="pageLargeLoadingIcon" icon="loading" />
			</h1>
			<div>
				<h4>
					<span id="modalPleaseWaitLabel">{text}</span>
				</h4>
			</div>
		</Loading>
	);
}

export default LargeLoading;

LargeLoading.defaultProps = {
	id: '',
	text: '',
};

LargeLoading.propTypes = {
	id: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
