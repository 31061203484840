import _ from 'lodash';
import moment from 'moment';
import { API_SERVER, SITE_URL } from './constants';
import body from '../assets/build-code.json';

export function getPageDetails(pageTitle) {
	const pageBody = (localStorage.getItem('json_structure') === null || localStorage.getItem('json_structure').length === 0) ? body : JSON.parse(localStorage.getItem('json_structure'));
	const page = _.filter(pageBody.pages, { name: pageTitle });
	return page[0];
}

export function buildComponents(pageTitle) {
	const localStorageBody = (localStorage.getItem('json_structure') === null || localStorage.getItem('json_structure').length === 0) ? {} : JSON.parse(localStorage.getItem('json_structure'));
	const pages = _.filter(localStorageBody.pages, { name: pageTitle });
	const page = pages[0];
	// remove name,link,modules from page array and assign whats left to parameters
	const parameters = _.omit(page, ['name', 'link', 'modules']);

	if (typeof (page.modules) === 'object') {
		// preload all components for page
		return Promise.all(page.modules.map((e) => {
			const module = _.filter(localStorageBody.modules, { name: e.key });
			const component = (typeof module[0] === 'object' && module[0].hasOwnProperty('component')) ? module[0].component : e.key;
			return import(`./../components/${component}.js`);
		}))
			.then((response) => {
				// set imported component to its module name in array
				const componentData = [];
				_.forEach(response, (module, index) => {
					componentData[page.modules[index].key] = module.default;
				});

				const newState = { modules: page.modules, components: componentData };
				if (typeof (parameters) !== 'undefined') {
					if (_.keys(parameters).length > 0) {
						_.forEach(parameters, (value, key) => {
							newState[key] = value;
						});
					}
				}

				// return all page data
				return newState;
			})
			.catch((err) => ({ error: err }));
	}
	return false;
}

/**
 * Save a users click session to the database
 * @param {number} kioskId
 * @param {string} url
 * @param {string} session
 * @param {string} lang
 * @param {string} country
 */
export function saveUserSession(kioskId, url, country, other) {
	// remove domain as we can merge touch metrics in Grafana
	const newUrl = url.replace(SITE_URL, '');
	fetch(`${API_SERVER}saveusersession`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			details: {
				kiosk_id: kioskId, other, url: newUrl, sessionId: localStorage.getItem('userSessionId'), lang: localStorage.getItem('selectedLanguage'), country, timestamp: moment().format('x'),
			},
		}),
	});
}
