import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { COUNCIL_MESSAGE_TIMEOUT, COUNCIL_VISIBLE_TIMEOUT } from '../utils/constants';

/**
 * Displays a message limited to a timer
 */
class CouncilMessage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggleMessage: false,
		};

		this.toggleMessageTimer = null;
		this.toggleVisibilityTimer = null;
		this.startTimer = this.startTimer.bind(this);
	}

	componentDidMount() {
		this.startTimer();
	}

	componentWillUnmount() {
		clearTimeout(this.toggleMessageTimer);
		clearTimeout(this.toggleVisibilityTimer);
	}

	/**
	 * [Displays a message, limited by a timer, clears any current message]
	 * @return {void}
	 */
	startTimer() {
		clearInterval(this.toggleMessageTimer);
		this.toggleMessageTimer = setInterval(() => {
			this.cycleMessage();
		}, COUNCIL_MESSAGE_TIMEOUT);
	}

	/**
	 * [Timeout for showing a message]
	 * @return {void}
	 */
	cycleMessage() {
		const { toggleMessage } = this.state;
		this.setState({
			toggleMessage: !toggleMessage,
		}, () => {
			this.toggleVisibilityTimer = setTimeout(() => {
				this.setState({ toggleMessage: !toggleMessage });
			}, COUNCIL_VISIBLE_TIMEOUT);
		});
	}

	/**
	 * [renders a message]
	 * @return {Component}
	 */
	render() {
		const { toggleMessage } = this.state;
		const { classes, handleClick } = this.props;
		return (
			<div id="councilMessageWrapper">
				{
					(toggleMessage) && <div className={classes} onClick={handleClick} role="button" tabIndex="0" aria-label="message" />
				}
			</div>
		);
	}
}

export default CouncilMessage;

CouncilMessage.defaultProps = { classes: '' };
CouncilMessage.propTypes = {
	classes: PropTypes.string,
	handleClick: PropTypes.func.isRequired,
};
