import React from 'react';
import PropTypes from 'prop-types';

/**
 * Master heading, use for extensino
 */
function Heading(props) {
	const {
		children,
		className,
		id,
		type,
	} = props;
	const Type = type;
	const headingId = (id.length > 0) ? id : '';
	const headingClass = (typeof className === 'string' && className.length > 0) ? className : '';

	return (
		<Type className={headingClass} id={headingId}>{children}</Type>
	);
}

export default Heading;

Heading.defaultProps = {
	className: '',
	id: '',
};

Heading.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	id: PropTypes.string,
	type: PropTypes.string.isRequired,
};
