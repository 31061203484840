import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { buildComponents, getPageDetails } from '../../utils/appFunctions';
import CouncilMessage from '../CouncilMessage';
import styles from '../../sass/Landing.module.css';
import WebIcon from '../reuseable/icons/WebIcon';
import ComponentContainer from '../reuseable/layouts/ComponentContainer';

class LandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			heading: 'Welcome Test',
			message: 'Touch Screen For Information',
			components: [],
			modules: [],
		};
		this.timer = null;
		this.pageDetails = {};

		this.goToMenu = this.goToMenu.bind(this);
		this.getPageModules = this.getPageModules.bind(this);
	}

	componentDidMount() {
		// this little function is for the commission page so that when the unit is
		// re-commissioned the page refreshes fully when you go back to the site
		const { history, location } = this.props;
		if (typeof (location) === 'object' && Object.keys(location).length > 0) {
			if (typeof (location.state) === 'object' && Object.keys(location.state).length > 0) {
				if (location.state.reload === true) {
					history.push({ state: { reload: false } });
					window.location.reload(true);
				}
			}
		}

		this.getPageModules();
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
		if (localStorage.getItem('userSessionActive') === 'false' || localStorage.getItem('userSessionActive') == null) {
			localStorage.setItem('userSessionActive', 'true');
			const randomNum = Number((Math.random() * 10000) + 1000);
			localStorage.setItem('userSessionId', moment().format('x') + Math.floor(randomNum));// 4 or 5 digit number plus timestamp in milliseconds
		}
	}

	getPageModules = async () => {
		try {
			this.pageDetails = await getPageDetails('LandingPage');
			const result = await buildComponents('LandingPage');
			this.setState(result);
		} catch (error) {
			// console.log('Some error occurred getting page modules');
		}
	}

	goToMenu() {
		const { history } = this.props;
		history.push(this.pageDetails.link);
	}

	render() {
		const {
			components,
			heading,
			message,
			modules,
		} = this.state;
		let countTop = 0; // height position for modules
		return (
			<div id="landingPageWrapper" className={styles.landingContainer}>
				{ (typeof (this.pageDetails.showMessage) === 'boolean' && this.pageDetails.showMessage === true) && <CouncilMessage classes={styles.landingMessage} handleClick={() => this.goToMenu()} />}
				<div className="landingComponents">
					{
						_.map(modules, (m, i) => {
							if (typeof (components) === 'object' && typeof (components[m.key]) !== 'undefined') {
								const PageComponent = components[m.key];
								const top = countTop;
								countTop += parseInt(m.height, 10);
								if (m.key === 'Html') {
									const icons = this.pageDetails.icons[0]; // icons
									return (
										<ComponentContainer key={`wrapper${i}`} id={m.key} width={m.width} height={m.height} top={top} left={0}>
											<PageComponent moduleKey={m.key}>
												<div className={styles.landingContainer} onClick={this.goToMenu} role="button" tabIndex="0">
													<h1 className={styles.title}><FormattedMessage id="page.landing.welcome" defaultMessage={heading} /></h1>
													<p className="text-center">
														<FormattedMessage id="page.landing.info" defaultMessage={message} />
														<br />
														<br />
														{
															Object.keys(icons).map((icon) => icons[icon]
																&& (
																	<span key={icon} className={`circle ${styles.circle}`}>
																		<WebIcon icon={icon} />
																	</span>
																))
														}
													</p>
												</div>
											</PageComponent>
										</ComponentContainer>
									);
								}

								return (
									<ComponentContainer
										key={i}
										id={m.key}
										width={m.width}
										height={m.height}
										top={top}
										left={0}
									>
										<PageComponent moduleKey={m.key} />
									</ComponentContainer>
								);
							}
							return false;
						})
					}
				</div>
			</div>
		);
	}
}

export default LandingPage;

LandingPage.defaultProps = {
	history: {},
	location: {},
};
LandingPage.propTypes = {
	history: PropTypes.shape({ push: PropTypes.func }),
	location: PropTypes.shape({ state: PropTypes.shape() }),
};
