import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

/**
 * Child icon, contains the weather icon
 */
function WeatherIcon(props) {
	const { className, icon, id } = props;
	return (
		<Icon type="wi" id={id} name={icon} className={className} />
	);
}

export default WeatherIcon;

WeatherIcon.defaultProps = {
	className: '',
	id: '',
};

WeatherIcon.propTypes = {
	className: PropTypes.string,
	icon: PropTypes.string.isRequired,
	id: PropTypes.string,
};
