import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import PrimaryButton from '../buttons/PrimaryButton';

const QRCode = require('qrcode.react');

/**
 * Toggles the display of a QR Link
 */
class ShowQRCode extends React.Component {
	/**
	 * [Generates QR link]
	 * @param  {int} lat
	 * @param  {int} lon
	 * @param  {int} tolat
	 * @param  {int} tolon
	 * @return {String}
	 */
	static generateQRCodeLink(lat, lon, tolat, tolon) {
		return `https://www.google.co.uk/maps/dir/?api=1&travelmode=walking&origin=${lat},${lon}&destination=${tolat},${tolon}`;
	}

	constructor(props) {
		super(props);
		this.state = {
			toggle: false,
		};

		this.toggleQrcode = this.toggleQrcode.bind(this);
	}

	toggleQrcode() {
		const { toggle } = this.state;
		this.setState({ toggle: !toggle });
	}

	render() {
		const { defaultCenter, direction } = this.props;
		const { toggle } = this.state;
		const qrLink = ShowQRCode.generateQRCodeLink(
			defaultCenter.lat,
			defaultCenter.lng,
			direction.lat,
			direction.lng,
		);

		const qrButtonMessage = (toggle === true) ? 'Hide QR Code' : 'Show QR Code';
		const qrButtonIntl = (toggle === true) ? 'hide-qr-code' : 'show-qr-code';

		return (
			<>
				{(toggle === true) && <QRCode id="direction_qrcode" value={qrLink} size={1000} />}
				<PrimaryButton id="direction_qrcode_button" handleClick={() => this.toggleQrcode()}><FormattedMessage id={`component.googlemap.${qrButtonIntl}`} defaultMessage={qrButtonMessage} /></PrimaryButton>
			</>
		);
	}
}

export default ShowQRCode;

ShowQRCode.defaultProps = {
	defaultCenter: {},
	direction: {},
};
ShowQRCode.propTypes = {
	defaultCenter: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	direction: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
};
