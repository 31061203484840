import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/nl';

import { COMMISSION_ACCESS_TIME, SITE_URL } from '../../utils/constants';
import helpers from '../../utils/helpers';
import Grid from '../reuseable/Grid';
import { getFromApi } from '../../services/apiFunctions';
import AirQualityWidget from '../reuseable/tables/AirQualityWidget';
import Clear from '../reuseable/Clear';
import WebIcon from '../reuseable/icons/WebIcon';
import WeatherIcon from '../reuseable/icons/WeatherIcon';

/**
 * Displays the header layout
 */
class Header extends Component {
	constructor(props) {
		super(props);
		moment.locale(props.language);

		this.state = {
			airQuality: false,
			weather: false,
			time: moment().format('HH:mm'),
			commissionAccessStartTime: false,
			commissionAccessTaps: 0,
			pageUrl: window.location.href,
			weatherRetry: 0,
			airQualityRetry: 0,
			logo: '',

			showTimeWidget: true,
			showWeatherWidget: false,
			showAirWidget: false,
		};

		this.datetime = null;
		this.getAirTimer = null;
		this.getWeatherTimer = null;
		this.goToHome = this.goToHome.bind(this);
		this.goToCommissioningPage = this.goToCommissioningPage.bind(this);
		this.updateLayout = this.updateLayout.bind(this);
		this.updatePageUrl = this.updatePageUrl.bind(this);
	}

	componentDidMount() {
		this.updateLayout();
	}

	/**
	 * Reset details
	 */
	componentDidUpdate(prevProps) {
		const { kioskDetails, language, location } = this.props;
		if (prevProps.kioskDetails !== kioskDetails) {
			clearInterval(this.datetime);
			clearInterval(this.getAirTimer);
			clearInterval(this.getWeatherTimer);
			this.updateLayout();
		}
		if (typeof (location) === 'object' && SITE_URL + location.pathname !== SITE_URL + prevProps.location.pathname) {
			this.updatePageUrl(location.pathname);
		}
		if (prevProps.language !== language) {
			moment.locale(language);
		}
	}

	componentWillUnmount() {
		// Stop all timers
		clearInterval(this.datetime);
		clearInterval(this.getAirTimer);
		clearInterval(this.getWeatherTimer);
		// Reset variables
		this.datetime = null;
		this.getAirTimer = null;
		this.getWeatherTimer = null;
	}

	/**
	 * Retrieves data from server
	 */
	getAirQuality = async () => {
		try {
			const response = await getFromApi('gettodayairquality');
			if (response.result === true) {
				this.setState({ airQuality: response.details });
			}
		} catch (error) {
			// console.log('Some error occurred in getAirQuality');
			const { airQualityRetry } = this.state;
			if (airQualityRetry < 3) {
				this.setState({ airQualityRetry: (airQualityRetry + 1) });
				setTimeout(() => {
					this.getAirQuality();
				}, 10000);// 10 sec, try again
			}
		}
	}

	/**
	 * Retrieves weather data from server, retrys on error
	 * @return {void} [Updates state]
	 */
	getWeather = async () => {
		try {
			const response = await getFromApi('gettodayweatherforecast');
			if (typeof (response) === 'object' && response.result !== false) {
				this.setState({ weather: response });
			}
		} catch (error) {
			// console.log('Some error occurred in getWeather');
			const { weatherRetry } = this.state;
			if (weatherRetry < 3) {
				this.setState({ weatherRetry: (weatherRetry + 1) });
				setTimeout(() => {
					this.getWeather();
				}, 10000);// 10 sec, try again
			}
		}
	}

	/**
	 * Redirect
	 * @param  {String} url
	 * @return {void} [Updates the state]
	 */
	updatePageUrl(url) {
		this.setState({ pageUrl: SITE_URL + url });
	}

	updateLayout() {
		const { kioskDetails } = this.props;
		if (typeof (kioskDetails) === 'object') {
			const state = {};
			if (kioskDetails.hasOwnProperty('unit_id')) {
				state.telNo = kioskDetails.unit_id;
				state.countryName = kioskDetails.country_name;
			}
			if (kioskDetails.hasOwnProperty('base64') && kioskDetails.base64 !== null && kioskDetails.base64.length > 0) {
				state.logo = kioskDetails.base64;
			}

			if (kioskDetails.hasOwnProperty('json_structure')) {
				const json = JSON.parse(kioskDetails.json_structure);
				if (json.hasOwnProperty('header')) {
					state.showTimeWidget = json.header.showTime;
					state.showWeatherWidget = json.header.showWeather;
					state.showAirWidget = json.header.showAirQuality;
				}
			}

			this.setState(state, () => {
				const { showAirWidget, showTimeWidget, showWeatherWidget } = this.state;
				if (showAirWidget === true) {
					this.getAirQuality();
					// checks every 30 minutes
					this.getAirTimer = setInterval(() => {
						this.getAirQuality();
					}, 1800000);
				}
				if (showWeatherWidget === true) {
					this.getWeather();
					// checks every 30 minutes
					this.getWeatherTimer = setInterval(() => {
						this.getWeather();
					}, 1800000);
				}
				if (showTimeWidget === true) {
					// checks every second
					this.datetime = setInterval(() => {
						this.setState({ time: moment().format('HH:mm') });
					}, 1000);
				}
			});
		}
	}

	/**
	 * Redirect
	 */
	goToHome() {
		const { history } = this.props;
		if (window.location.href.includes('/commission') === true) {
			history.push({ pathname: '/', state: { reload: true } });
		} else {
			history.push('/');
		}
	}

	goToCommissioningPage() {
		const { commissionAccessStartTime, commissionAccessTaps, pageUrl } = this.state;
		const { history } = this.props;
		// time check
		if (pageUrl === `${SITE_URL}/terms-conditions`) {
			if (commissionAccessStartTime) {
				if (moment().diff(commissionAccessStartTime, 'seconds') >= COMMISSION_ACCESS_TIME) {
					// time out occurred
					this.setState({ commissionAccessTaps: 0, commissionAccessStartTime: false });
				} else {
					this.setState({ commissionAccessTaps: (commissionAccessTaps + 1) });

					if (commissionAccessTaps >= 5) {
						// gained access to commission page
						history.push('/commission');
					}
				}
			} else {
				// start
				this.setState({ commissionAccessTaps: 1, commissionAccessStartTime: moment().format() });
			}
		}
	}

	render() {
		const {
			airQuality,
			logo,
			showAirWidget,
			showTimeWidget,
			showWeatherWidget,
			time,
			weather,
			telNo,
			countryName,
		} = this.state;
		return (
			<header className="primary-bg-color App-header">
				<Grid hideMobile tablet={4} classes="tableDisplay text-center" id="secret">
					{
						(showTimeWidget)
							? (
								<span id="dateTimeWidget" className="date-time middle" onClick={this.goToCommissioningPage} role="button" tabIndex="0">
									<span className="date">{ moment().format('dddd, D MMMM, YYYY') }</span>
									<br />
									<span className="time">{time}</span>
								</span>
							)
							: (<span />)
					}
				</Grid>
				<Grid mobile={6} tablet={4} classes="text-center">
					<img src={`data:image/png;base64,${logo}`} className="App-logo" alt="logo" onError={(e) => { e.target.onerror = null; e.target.src = '/images/logo-default.png'; }} />
				</Grid>
				<Grid hideMobile tablet={2} classes="text-center no-padding">
					{
						(typeof weather === 'object' && showWeatherWidget) && (
							<div id="weatherForecastWidget">
								<div>
									<WeatherIcon icon={weather.img} />
									{helpers.OutputTemp(weather.high)}
								</div>
							</div>
						)
					}

					{
						(showAirWidget) && <AirQualityWidget level={airQuality.level} />
					}
					{
						(countryName === 'Ireland') && (
							<div className="TelNo">
								Tel:&nbsp;
								{telNo}
							</div>
						)
					}
				</Grid>
				<Grid mobile={6} tablet={2} id="header_goHomeLink" classes="tableDisplay text-center">
					<div className="middle">
						<span id="goHomeLink" onClick={this.goToHome} className="circle" role="button" tabIndex="0" aria-label="Link Home"><WebIcon icon="home" /></span>
					</div>
				</Grid>

				<Clear />
			</header>
		);
	}
}

export default withRouter(Header);

Header.defaultProps = {
	history: {},
	language: 'en',
	kioskDetails: { base64: '', unit_id: '', country_name: '' },
	location: {},
};
Header.propTypes = {
	history: PropTypes.shape({ push: PropTypes.func }),
	language: PropTypes.string,
	location: PropTypes.shape({ pathname: PropTypes.string }),
	kioskDetails: PropTypes.shape(
		{
			base64: PropTypes.string,
			json_structure: PropTypes.string,
			unit_id: PropTypes.string,
			country_name: PropTypes.string,
		},
	),
};
