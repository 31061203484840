import React from 'react';
import Button from '../../reuseable/buttons/Button';
import { API_SERVER } from '../../../utils/constants';

const setBrightness = async (howBright) => {
	const response = await fetch(`${API_SERVER}prisma/?type=BRS&value=${howBright}`);
	const jsonBody = await response.json();
	return jsonBody;
};

function CommissionTestMode() {
	return (
		<div className="commissionTestContainer text-center">
			<Button id="blsb10" classes="default-button margr15" handleClick={() => setBrightness(0)}>0%</Button>
			<Button id="blsb10" classes="default-button margr15" handleClick={() => setBrightness(20)}>20%</Button>
			<Button id="blsb10" classes="default-button margr15" handleClick={() => setBrightness(40)}>40%</Button>
			<Button id="blsb10" classes="default-button margr15" handleClick={() => setBrightness(60)}>60%</Button>
			<Button id="blsb10" classes="default-button margr15" handleClick={() => setBrightness(80)}>80%</Button>
			<Button id="blsb10" classes="default-button margr15" handleClick={() => setBrightness(100)}>100%</Button>
		</div>
	);
}

export default CommissionTestMode;
