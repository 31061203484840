import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SecondaryButton from './buttons/SecondaryButton';
import SuccessButton from './buttons/SuccessButton';

const ModalSecondaryButton = styled(SecondaryButton)`
  margin:0;
`;
const ModalSuccessButton = styled(SuccessButton)`
  margin:0;
`;

/**
 * Master component for extending modals
 */
class PopupModal extends Component {
	constructor(props) {
		super(props);

		this.handleClose = this.handleClose.bind(this);
		this.handleConfirm = this.handleConfirm.bind(this);
	}

	handleClose() {
		const { cancel } = this.props;
		cancel();
	}

	handleConfirm() {
		const { confirm: confirmProp } = this.props;
		confirmProp();
	}

	render() {
		let modalType = '';
		const {
			show,
			classname,
			cancelName,
			cancelNameID,
			confirmName,
			confirmNameID,
			message,
			title,
			type,
		} = this.props;

		if (type.length > 0) {
			switch (type) {
			case 'danger':
				modalType = ' alert-danger';
				break;
			case 'warning':
				modalType = ' alert-warning';
				break;
			default:
				break;
			}
		}

		return (
			<div className="static-modal modal-dialog">
				<Modal show={show} onHide={this.handleClose} className={classname}>
					<Modal.Header closeButton className={modalType}>
						<Modal.Title>{title}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{message}
					</Modal.Body>
					<Modal.Footer>
						{
							(typeof confirmName === 'string') && <ModalSuccessButton handleClick={this.handleConfirm} id={confirmNameID}>{confirmName}</ModalSuccessButton>
						}
						<ModalSecondaryButton handleClick={this.handleClose} id={cancelNameID}>
							{cancelName}
						</ModalSecondaryButton>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

export default PopupModal;

PopupModal.defaultProps = {
	classname: '',
	cancelName: '',
	cancelNameID: '',
	confirmName: '',
	confirmNameID: '',
	message: '',
	show: false,
	title: '',
	type: '',
};
PopupModal.propTypes = {
	cancel: PropTypes.func.isRequired,
	classname: PropTypes.string,
	cancelName: PropTypes.string,
	cancelNameID: PropTypes.string,
	confirm: PropTypes.func.isRequired,
	confirmName: PropTypes.string,
	confirmNameID: PropTypes.string,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	show: PropTypes.bool,
	title: PropTypes.string,
	type: PropTypes.string,
};
