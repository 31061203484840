import React from 'react';
import PropTypes from 'prop-types';

/**
 * Master component, used to set loading spinner
 */
function Loading(props) {
	const { children, id } = props;
	return (
		<div id="loadingWrapper">
			<div id={id} className="modal-backdrop backdrop-absolute fade in" />
			<div id="modalPleaseWait" role="dialog" className="">
				<div className="text-center">
					{children}
				</div>
			</div>
		</div>
	);
}

export default Loading;

Loading.defaultProps = { id: '' };
Loading.propTypes = {
	children: PropTypes.node.isRequired,
	id: PropTypes.string,
};
