import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

/**
 * Child button, used for commission page
 */
function CommissionPageButton(props) {
	const { buttonClick, classes, title } = props;
	return (
		<div className="col-sm-4 text-center">
			<Button className={`pageLink ${classes}`} handleClick={() => buttonClick()}>{title}</Button>
		</div>
	);
}

export default CommissionPageButton;

CommissionPageButton.defaultProps = {
	classes: '',
	title: '',
};
CommissionPageButton.propTypes = {
	buttonClick: PropTypes.func.isRequired,
	classes: PropTypes.string,
	title: PropTypes.string,
};
