import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { API_SERVER, COMMISSION_BACKEND_TIMEOUT, COMMISSION_ACCESS_CODE } from '../../../utils/constants';
import { getKioskDetails } from '../../../services/apiFunctions';
import { TopHeading } from '../../reuseable/Headings';

import CommissionKioskDetails from './CommissionKioskDetails';
import CommissionStatusInfo from './CommissionStatusInfo';
import CommissionTestMode from './CommissionTestMode';
import CommissionNewKiosk from './CommissionNewKiosk';
import CommissionLogin from './CommissionLogin';
import CommissionPageButton from '../../reuseable/buttons/CommissionPageButton';

import CommissionReset from '../../reuseable/modals/CommissionReset';
import SuccessButton from '../../reuseable/buttons/SuccessButton';
import Button from '../../reuseable/buttons/Button';
import ErrorAlert from '../../reuseable/ErrorAlert';
import Clear from '../../reuseable/Clear';
import WebIcon from '../../reuseable/icons/WebIcon';

/**
 * Master layout for the commission page
 */
class Commission extends Component {
	constructor(props) {
		super(props);

		this.state = {
			kioskDetails: {},
			position: {},
			wait: true,
			enteredCode: '',
			loadKioskError: true,
			page: 'kiosk',
			decommissioning: false,
			resetKioskToggle: false,
		};

		this.userSessionTimer = null;

		this.loadKiosk = this.loadKiosk.bind(this);
		this.goToHome = this.goToHome.bind(this);
		this.changePage = this.changePage.bind(this);
		this.decommission = this.decommission.bind(this);
		this.userSessionTimeout = this.userSessionTimeout.bind(this);
		this.decommissionConfirmation = this.decommissionConfirmation.bind(this);
		this.updateState = this.updateState.bind(this);
	}

	/**
	 * Reset user session
	 */
	componentDidMount() {
		window.addEventListener('click', this.userSessionTimeout);
		window.addEventListener('touchmove', this.userSessionTimeout);

		localStorage.setItem('userSessionActive', 'false');
		localStorage.setItem('userSessionId', '');
		localStorage.setItem('userSessionNavigation', '');
		this.loadKiosk();
		this.userSessionTimeout();// start timeout session
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.userSessionTimeout);
		window.removeEventListener('touchmove', this.userSessionTimeout);
	}

	/**
	 * Get kiosk details
	 */
	loadKiosk = async () => {
		try {
			const result = await getKioskDetails();
			this.setState({
				kioskDetails: result,
				position: { lat: result.lat, lng: result.lon },
				// rotation: result.rot,
				loadKioskError: false,
			});
		} catch (error) {
			if (error.hasOwnProperty('message') && error.message === 'Failed to fetch') {
				// API is down
				this.setState({ loadKioskError: false });
			} else {
				// kiosk not initialised
				await this.initialise()
					.then(() => this.setState({ loadKioskError: false }))
					.catch(() => this.setState({ loadKioskError: true }));
			}
		} finally {
			this.setState({ wait: false });
		}
	}

	/**
	 * clear db and rebuild tables
	 * @return {String}
	 */
	initialise = async () => {
		const response = await fetch(`${API_SERVER}initialise`);
		const body = await response.json();
		return body;
	}

	/**
	 * This function checks inactivity on the page, if unused it will return to touch screen
	 */
	userSessionTimeout() {
		const { history } = this.props;
		clearInterval(this.userSessionTimer);
		this.userSessionTimer = setInterval(() => {
			clearInterval(this.userSessionTimer);
			// link user back to landing page
			history.push('/');
		}, COMMISSION_BACKEND_TIMEOUT);
	}

	updateState(newstate) {
		this.setState(newstate);
	}

	/**
	 * Redirect on click
	 */
	goToHome() {
		const { history } = this.props;
		history.push({ pathname: '/', state: { reload: true } });
	}

	/**
	 * Confimation message if decommission is clicked
	 */
	decommissionConfirmation() {
		const { resetKioskToggle } = this.state;
		this.setState({ resetKioskToggle: !resetKioskToggle });
	}

	/**
	 * On decommission delete all table data (not logs)
	 */
	decommission() {
		const t = this;
		this.setState({ wait: true, decommissioning: true, resetKioskToggle: false }, () => {
			localStorage.removeItem('json_structure');

			fetch(`${API_SERVER}decommission`)
				.then((response) => response.json())
				.then(() => t.initialise())
				.finally(() => {
					t.setState({
						wait: false,
						decommissioning: false,
						kioskDetails: {},
						position: {},
					});
				});
		});
	}

	changePage(page) {
		this.setState({ page });
	}

	/**
	 * Renders the entire commission page
	 * @return {Component}
	 */
	render() {
		const {
			decommissioning,
			enteredCode,
			loadKioskError,
			kioskDetails,
			page,
			position,
			resetKioskToggle,
			wait,
		} = this.state;
		return (
			<div className="commissionContainer">
				<TopHeading>Commissioning Page</TopHeading>
				<div>
					<div className="white-container absolute overflow-y">
						{
							(enteredCode !== COMMISSION_ACCESS_CODE)
								? (<CommissionLogin successCode={(newstate) => this.updateState(newstate)} />)
								: (
									<div>
										{
											(loadKioskError) ? (
												<ErrorAlert>
													{'There was a problem loading the kiosk details, sometimes this is because the API has stopped working.'}
													<br />
													<span onClick={this.loadKiosk} role="button" tabIndex="0">Click here to Reload Details</span>
												</ErrorAlert>
											)
												: (
													<div>
														{
															(wait) ? (
																<div className="waitWrapper">
																	<div>
																		<p className="wait text-center">
																			<WebIcon icon="loading" />
																			{' Please Wait...'}
																			{
																				(decommissioning) && <span>Kiosk is decommissioning</span>
																			}
																		</p>
																	</div>
																</div>
															)
																: (
																	<div className="col-sm-12 no-padding">
																		{
																			(Object.keys(kioskDetails).length > 0) && (
																				<div>
																					<CommissionPageButton classes={(page === 'kiosk') ? 'active' : ''} title="Kiosk Details" buttonClick={() => this.changePage('kiosk')} />
																					<CommissionPageButton classes={(page === 'test') ? 'active' : ''} title="Test Mode" buttonClick={() => this.changePage('test')} />
																					<CommissionPageButton classes={(page === 'status') ? 'active' : ''} title="Status Info" buttonClick={() => this.changePage('status')} />
																				</div>
																			)
																		}

																		{
																			(page === 'kiosk') && (
																				<>
																					{
																						(Object.keys(kioskDetails).length > 0) ? (
																							<CommissionKioskDetails
																								kioskDetails={kioskDetails}
																								position={position}
																								updateState={
																									(newstate) => this.updateState(newstate)
																								}
																							/>
																						)
																							: (
																								<CommissionNewKiosk
																									stateUpdate={
																										(newstate) => this.updateState(newstate)
																									}
																								/>
																							)
																					}
																				</>
																			)
																		}
																		{
																			(page === 'test') && <CommissionTestMode />
																		}
																		{
																			(page === 'status') && <CommissionStatusInfo />
																		}
																	</div>
																)
														}
													</div>
												)
										}
									</div>
								)
						}

						<Clear />

						<p className="text-center">
							{ (enteredCode === COMMISSION_ACCESS_CODE && page === 'kiosk' && decommissioning === false && Object.keys(kioskDetails).length > 0)
								&& <Button id="reset_data" classes="default-button margr15" handleClick={() => this.decommissionConfirmation()}>Reset Data</Button>}
							<SuccessButton id="exitKiosk" classes="exit-kiosk" handleClick={() => this.goToHome()}>Exit to Touch Screen</SuccessButton>
						</p>
					</div>
				</div>

				{
					resetKioskToggle && (
						<CommissionReset
							show={resetKioskToggle}
							handleCancel={this.decommissionConfirmation}
							handleConfirm={this.decommission}
						/>
					)
				}
			</div>
		);
	}
}

export default Commission;

Commission.defaultProps = {
	history: {},
};
Commission.propTypes = {
	history: PropTypes.shape({ push: PropTypes.func }),
};
