import React from 'react';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import PropTypes from 'prop-types';

/**
 * Master component to render an alert message
 */
function Alert(props) {
	const {
		children,
		classes,
		id,
		type,
	} = props;
	return (
		<BootstrapAlert bsStyle={type} className={classes} id={id}>{children}</BootstrapAlert>
	);
}

export default Alert;

Alert.defaultProps = {
	classes: '',
	id: '',
	type: 'info',
};

Alert.propTypes = {
	children: PropTypes.node.isRequired,
	classes: PropTypes.string,
	id: PropTypes.string,
	type: PropTypes.string,
};
