import React from 'react';
import PropTypes from 'prop-types';
import Alert from './Alert';

/**
 * Child component to show any error alerts
 */
function ErrorAlert(props) {
	const { children, classes, id } = props;
	return (
		<Alert type="danger" classes={classes} id={id}>{children}</Alert>
	);
}

export default ErrorAlert;

ErrorAlert.defaultProps = {
	classes: '',
	id: '',
};

ErrorAlert.propTypes = {
	children: PropTypes.node.isRequired,
	classes: PropTypes.string,
	id: PropTypes.string,
};
