import React from 'react';
import PropTypes from 'prop-types';
import Alert from './Alert';

/**
 * Child component for showing any info alerts
 */
function InfoAlert(props) {
	const { children, classes, id } = props;
	return (
		<Alert type="info" classes={classes} id={id}>{children}</Alert>
	);
}

export default InfoAlert;

InfoAlert.defaultProps = {
	classes: '',
	id: '',
};

InfoAlert.propTypes = {
	children: PropTypes.node.isRequired,
	classes: PropTypes.string,
	id: PropTypes.string,
};
