import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import $ from 'jquery';
import PropTypes from 'prop-types';
import LargeLoading from './reuseable/loading/LargeLoading';
import WebIcon from './reuseable/icons/WebIcon';
import ShowQRCode from './reuseable/maps/ShowQRCode';
import ShowMapError from './reuseable/maps/ShowMapError';
import ShowMapTimeout from './reuseable/maps/ShowMapTimeout';

import {
	addCircle, addCustomMarker, addDirection, addLabelMarker, addMarker, createMapWithBoundary,
} from './reuseable/maps/GoogleElements';

window.$ = $;
window.jQuery = $;

let { google } = window;
let map = false;
const allMarkers = [];
const allCircles = [];

/**
 * Leaving comments in this file as not sure if it needs updating
 */
const loadGoogleMaps = (callback, errorCallback) => {
	const existingScript = document.getElementById('googleMaps');

	if (!existingScript) {
		const script = document.createElement('script');
		script.src = 'https://maps.googleapis.com/maps/api/js?v=3.30&client=gme-clearchannelinternational';
		script.id = 'googleMaps';
		document.body.appendChild(script);

		script.onload = () => {
			const { google: googleObj } = window;
			google = googleObj;
			if (callback) callback();
		};

		script.onerror = () => {
			if (errorCallback) errorCallback();
		};
	}

	if (existingScript && callback) callback();
};

/**
 * Creates a google maps component
 */
class GoogleMapComponent extends React.Component {
	static clearMapMarkers() {
		// clear previous markers
		for (let i = 0; i < allMarkers.length; i += 1) {
			allMarkers[i].setMap(null);
		}
		allMarkers.length = 0;

		// clear previous circles
		for (let c = 0; c < allCircles.length; c += 1) {
			allCircles[c].setMap(null);
		}
		allCircles.length = 0;
	}

	constructor(props) {
		super(props);
		this.state = {
			mapDraggable: (typeof (props.mapDraggable) === 'boolean') ? props.mapDraggable : true,
			markerDraggable: (typeof (props.markerDraggable) === 'boolean') ? props.markerDraggable : false,
			canZoom: (typeof (props.canZoom) === 'boolean') ? props.canZoom : true,
			zoom: (props.zoom) ? props.zoom : 16,
			minZoom: 15,
			maxZoom: 19,
			defaultCenter: (typeof (props.defaultCenter) === 'object') ? props.defaultCenter : {},
			direction: (typeof (props.direction) === 'object') ? props.direction : {},
			mapTimedOut: false,
			loading: true,
		};

		this.failedTimer = null;

		this.loadMapData = this.loadMapData.bind(this);
		this.centerMap = this.centerMap.bind(this);
		this.renderPOIAllLinks = this.renderPOIAllLinks.bind(this);
		this.updateProps = this.updateProps.bind(this);
	}

	componentDidMount() {
		loadGoogleMaps(() => {
			// SUCCESS CALLBACK
			// start timeout as map should load in 10 seconds
			this.failedTimer = setInterval(() => {
				this.setState({ mapTimedOut: true, loading: false });
			}, 10000);

			this.updateProps(this.props);
		}, () => {
			// ERROR CALLBACK
			// script failed so show error message to user
			this.setState({ loading: false });
		});
	}

	/**
	 * @todo [Is this correct]
	 */
	componentDidUpdate(prevProps) {
		if (this.props !== prevProps) {
			if (typeof (google) === 'object') {
				this.updateProps(this.props);
			} else {
				// wait a number of seconds and then call updateProps();
			}
		}
	}

	componentWillUnmount() {
		clearInterval(this.failedTimer);
	}

	updateProps(props) {
		const newProps = { ...props };
		this.setState(newProps, () => {
			this.loadMapData();
		});
	}

	loadMapData() {
		const {
			canZoom,
			defaultCenter,
			mapDraggable,
			markerDraggable,
			minZoom,
			maxZoom,
			zoom,
		} = this.state;
		const {
			additionalMarkers,
			direction,
			isMarkerShown,
			showArrowMarker,
			show5MinuteRing,
			show10MinuteRing,
			show15MinuteRing,
		} = this.props;
		clearInterval(this.failedTimer);
		if (_.size(defaultCenter)
			&& defaultCenter.lat !== undefined
			&& defaultCenter.lng !== undefined
		) {
			const t = this;

			// default map settings
			const mapOptions = {
				draggable: mapDraggable,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				clickableIcons: false,
			};
			// toggle map zoom options
			if (canZoom === true) {
				mapOptions.minZoom = minZoom;
				mapOptions.maxZoom = maxZoom;
				mapOptions.zoomControl = true;
			} else {
				mapOptions.zoomControl = false;
				mapOptions.scrollwheel = false;
				mapOptions.disableDoubleClickZoom = true;
			}

			map = createMapWithBoundary(google, 'map', zoom, defaultCenter, mapOptions);

			if (typeof (google.maps.event) === 'object') {
				GoogleMapComponent.clearMapMarkers();
				if (isMarkerShown) {
					allMarkers.push(addMarker(google, map, defaultCenter));
				}
				if (show5MinuteRing) {
					// add ring around center and show label
					allCircles.push(addCircle(google, map, defaultCenter, 325, '#FF0000'));
					const label5Pos = { lat: (defaultCenter.lat + 0.0025), lng: defaultCenter.lng };
					allMarkers.push(addLabelMarker(google, map, label5Pos, '5 minute walk'));
				}
				if (show10MinuteRing) {
					// add ring around center and show label
					allCircles.push(addCircle(google, map, defaultCenter, 655, '#158a00'));
					const label10Pos = { lat: (defaultCenter.lat + 0.0055), lng: defaultCenter.lng };
					allMarkers.push(addLabelMarker(google, map, label10Pos, '10 minute walk'));
				}
				if (show15MinuteRing) {
					// add ring around center and show label
					allCircles.push(addCircle(google, map, defaultCenter, 980, '#000000'));
					const label15Pos = { lat: (defaultCenter.lat + 0.0085), lng: defaultCenter.lng };
					allMarkers.push(addLabelMarker(google, map, label15Pos, '15 minute walk'));
				}
				if (typeof (additionalMarkers) === 'object' && additionalMarkers.length > 0) {
					additionalMarkers.forEach((item) => {
						addCustomMarker(google, map, item);
					});
				}

				if (typeof (showArrowMarker) === 'boolean' && showArrowMarker === true) {
					// arrow marker shows the direction the kiosk is facing
					const symbolPathArrow = (google.maps.hasOwnProperty('SymbolPath')) ? google.maps.SymbolPath.FORWARD_CLOSED_ARROW : '';
					const arrowIcon = {
						path: symbolPathArrow,
						strokeColor: (markerDraggable) ? 'red' : 'black',
						scale: 4,
						rotation: 0,
					};
					allMarkers.push(addMarker(google, map, defaultCenter, arrowIcon, false, true));
					const arrowMarker = allMarkers[allMarkers.length - 1]; // last in array
					google.maps.event.addListener(arrowMarker, 'dragend', (am) => {
						const { latLng } = am;
						t.props.updatePosition(latLng.lat(), latLng.lng());
					});
				}

				// build directions
				if (typeof (direction) === 'object' && _.size(direction)) {
					addDirection(google, map, defaultCenter, direction);
				}

				google.maps.event.addListener(map, 'tilesloaded', () => {
					// stop timeout timer as google is loaded
					clearInterval(this.failedTimer);
				});
			}
		}
	}

	centerMap() {
		const { defaultCenter } = this.state;
		if (map && typeof (map.setCenter) !== 'undefined') {
			map.setCenter(defaultCenter);
		}
	}

	renderPOIAllLinks() {
		// This function adds hidden links to the page which the jQuery at the
		// top of the page hits when the user
		// clicks a POI in the /all section of categories
		const { additionalMarkers } = this.props;
		if (typeof (additionalMarkers) === 'object' && additionalMarkers.length > 0) {
			return (
				additionalMarkers.map((item, i) => <Link id={item.placeId} to={item.link} key={`POIAllLink${i + 1}`}>{item.name}</Link>)
			);
		}
		return false;
	}

	render() {
		const {
			canZoom,
			defaultCenter,
			direction,
			loading,
			mapTimedOut,
		} = this.state;
		const { showQRCode } = this.props;

		return (
			<>
				<div id="map" className="text-left white-background">
					{
						(loading)
							? (<LargeLoading id="loading_googlemaps" text="Loading" />)
							: (
								<>
									{ (typeof (google) === 'undefined' || typeof (google) !== 'object') && <ShowMapError /> }
									{ (mapTimedOut) && <ShowMapTimeout /> }
								</>
							)
					}
				</div>
				<span id="overlayGoogleLogo" />
				<>{this.renderPOIAllLinks()}</>

				{
					(canZoom === true && typeof (google) === 'object') && (<div id="centerButton" className="map-button map-center" onClick={() => this.centerMap()} title="Center Map" role="button" tabIndex="0" aria-label="map center"><WebIcon icon="crosshairs" /></div>)
				}

				{(showQRCode === true) && (
					<ShowQRCode defaultCenter={defaultCenter} direction={direction} />
				)}
			</>
		);
	}
}

export default withRouter(GoogleMapComponent);

GoogleMapComponent.defaultProps = {
	additionalMarkers: [],
	canZoom: true,
	defaultCenter: {},
	direction: {},
	isMarkerShown: false,
	mapDraggable: true,
	markerDraggable: false,
	showArrowMarker: false,
	show5MinuteRing: true,
	show10MinuteRing: true,
	show15MinuteRing: false,
	showQRCode: false,
	zoom: 16,
};
GoogleMapComponent.propTypes = {
	additionalMarkers: PropTypes.arrayOf(PropTypes.object),
	canZoom: PropTypes.bool,
	defaultCenter: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	direction: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	isMarkerShown: PropTypes.bool,
	mapDraggable: PropTypes.bool,
	markerDraggable: PropTypes.bool,
	showArrowMarker: PropTypes.bool,
	show5MinuteRing: PropTypes.bool,
	show10MinuteRing: PropTypes.bool,
	show15MinuteRing: PropTypes.bool,
	showQRCode: PropTypes.bool,
	zoom: PropTypes.number,
};
