import React from 'react';
import PropTypes from 'prop-types';

/**
 * Container mainly used to set dimensions
 */
function ComponentContainer(props) {
	const {
		children,
		height,
		id,
		left,
		top,
		width,
	} = props;
	return (
		<div
			id={`module_${id}`}
			className="module-container"
			style={{
				width: `${width}%`,
				height: `${height}%`,
				top: `${top}%`,
				left: `${left}%`,
			}}
		>
			{children}
		</div>
	);
}

export default ComponentContainer;

ComponentContainer.defaultProps = {
	id: '',
	width: '',
	height: '',
	top: 0,
	left: 0,
};

ComponentContainer.propTypes = {
	children: PropTypes.node.isRequired,
	id: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
	top: PropTypes.number,
	left: PropTypes.number,
};
