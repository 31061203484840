import React, { Component } from 'react';
import { Table as BootstrapTable } from 'react-bootstrap';
import PropTypes from 'prop-types';

/**
 * Master component, setup for a basic table
 */
class Table extends Component {
	renderTableData() {
		const { data } = this.props;
		const i = 1;
		const j = 1;
		if (typeof (data) === 'object' && data.length > 0) {
			return data.map((tdData, index) => (
				<tr key={`TableData${i + index}`}>
					{
						tdData.map((tdvalues, tdindex) => {
							const cspan = (typeof tdvalues.colspan !== 'undefined') ? tdvalues.colspan : 1;
							return (<td colSpan={cspan} key={`TableDataRow${j + tdindex}`} className={tdvalues.classes}>{tdvalues.value}</td>);
						})
					}
				</tr>
			));
		}

		return false;
	}

	renderTableHeader() {
		const { headers } = this.props;
		if (typeof (headers) === 'object' && headers.length > 0) {
			return (
				<thead>
					<tr>
						{
							headers.map((thvalues, thindex) => {
								const key = thindex + 1;
								const cspan = (typeof thvalues.colspan !== 'undefined') ? thvalues.colspan : 1;
								return (
									<th colSpan={cspan} key={key} className={thvalues.classes}>{thvalues.value}</th>
								);
							})
						}
					</tr>
				</thead>
			);
		}

		return false;
	}

	render() {
		const { classes, className, id } = this.props;
		let customClasses = (classes.length > 0) ? classes : '';
		customClasses += (className.length > 0) ? ` ${className}` : '';
		return (
			<BootstrapTable id={id} className={customClasses}>
				{ this.renderTableHeader() }
				<tbody>
					{ this.renderTableData() }
				</tbody>
			</BootstrapTable>
		);
	}
}

export default Table;

Table.defaultProps = {
	classes: '',
	className: '',
	headers: [],
	id: '',
};

Table.propTypes = {
	classes: PropTypes.string,
	className: PropTypes.string,
	data: PropTypes.arrayOf(
		PropTypes.array,
	).isRequired,
	headers: PropTypes.arrayOf(
		PropTypes.shape({
			classes: PropTypes.string,
			colspan: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			value: PropTypes.sting,
		}),
	),
	id: PropTypes.string,
};
