import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import styled from 'styled-components';
import { addLocaleData, IntlProvider } from 'react-intl';
// Import Language
import localeEn from 'react-intl/locale-data/en';
import localeFr from 'react-intl/locale-data/fr';
import PropTypes from 'prop-types';

import AccessibilityProvider from './hooks/accessibility-provider';
import Root from './Root';
import { USER_SESSION_TIMEOUT, COLOUR_INVERT_TIMER } from './utils/constants';
import { saveUserSession } from './utils/appFunctions';

import Header from './components/presentational/Header';
import Footer from './components/presentational/Footer';
import { getKioskDetails } from './services/apiFunctions';
import body from './assets/build-code.json';

// JSON Forms
import messagesEn from './translations/en.json';
import messagesFr from './translations/fr.json';

addLocaleData([...localeEn, ...localeFr]);
const messages = {
	fr: messagesFr,
	en: messagesEn,
};
// const language = navigator.language.split(/[-_]/)[0];  // language without region code

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			lastUrl: '',
			defaultLanguage: 'en',
			selectedLanguage: 'en',
			background: '',
		};

		this.showCursor = null;
		this.userSessionTimer = null;
		this.invertColourTimer = null;
		this.userSessionTimeout = this.userSessionTimeout.bind(this);
		this.invertColourInactivity = this.invertColourInactivity.bind(this);
		this.fetchKioskDetails = this.fetchKioskDetails.bind(this);
		this.changeLanguage = this.changeLanguage.bind(this);
	}

	componentDidMount() {
		this.fetchKioskDetails();

		// userSessionTimeout initialise
		if (localStorage.getItem('userSessionActive') == null) {
			const { defaultLanguage } = this.state;
			localStorage.setItem('userSessionActive', 'false');
			localStorage.setItem('userSessionId', '');
			localStorage.setItem('userSessionNavigation', '');
			localStorage.setItem('selectedLanguage', defaultLanguage);
		}
		window.addEventListener('click', this.userSessionTimeout);
		window.addEventListener('touchmove', this.userSessionTimeout);

		// 5 minute color change for inactivity
		this.invertColourInactivity();
		window.addEventListener('click', this.invertColourInactivity);
		window.addEventListener('touchmove', this.invertColourInactivity);
	}

	fetchKioskDetails = async () => {
		try {
			const result = await getKioskDetails();
			// updating the json structure
			let jsonStructure = '';
			if (result.json_structure.length > 0) {
				jsonStructure = result.json_structure;
			} else {
				jsonStructure = JSON.stringify(body);
			}
			if (jsonStructure !== localStorage.getItem('json_structure')) {
				localStorage.setItem('json_structure', jsonStructure);
				window.location.reload();
			}

			const state = { kioskDetails: result };

			// language
			if (result.default_language) {
				state.defaultLanguage = result.default_language;
				state.selectedLanguage = result.default_language;
			}

			// setting new colour schemes
			if (result.primary_bg_color !== null && result.primary_bg_color !== 'null' && result.primary_bg_color.length > 0) {
				document.body.style.setProperty('--primary-background-color', result.primary_bg_color);
			}
			if (result.primary_text !== null && result.primary_text !== 'null' && result.primary_text.length > 0) {
				document.body.style.setProperty('--primary-background-text', result.primary_text);
			}
			if (result.secondary_bg_color !== null && result.secondary_bg_color !== 'null' && result.secondary_bg_color.length > 0) {
				document.body.style.setProperty('--secondary-background-color', result.secondary_bg_color);
			}
			if (result.secondary_text !== null && result.secondary_text !== 'null' && result.secondary_text.length > 0) {
				document.body.style.setProperty('--secondary-background-text', result.secondary_text);
			}
			if (result.news_bg_color !== null && result.news_bg_color !== 'null' && result.news_bg_color.length > 0) {
				document.body.style.setProperty('--news-background-color', result.news_bg_color);
			}

			if (result.background_base64 !== null && result.background_base64.length > 0) {
				state.background = result.background_base64;
				// this.setState({background:result.background_base64});
			}

			this.setState(state);
		} catch (error) {
			// console.log("Some error occurred: "+error);
			if (JSON.stringify(body) !== localStorage.getItem('json_structure')) {
				localStorage.setItem('json_structure', JSON.stringify(body));
				window.location.reload();
			}
		}
	}

	// This function handles the users browsing session, it refreshes on every touch and after
	// 2 minutes their session will timeout
	// and we will store their session in a database
	userSessionTimeout() {
		const { defaultLanguage, kioskDetails, lastUrl } = this.state;
		const { history } = this.props;

		if (lastUrl !== window.location.href && localStorage.getItem('userSessionNavigation') !== null) {
			const currentNav = (localStorage.getItem('userSessionNavigation').length > 0) ? localStorage.getItem('userSessionNavigation').split(',') : [];
			if (currentNav[currentNav.length - 1] !== window.location.href) {
				currentNav.push(window.location.href);
				// save on each unique page click
				const kioskId = (typeof kioskDetails === 'object') ? kioskDetails.kiosk_id : '';
				const countryName = (typeof kioskDetails === 'object') ? kioskDetails.country_name : '';
				saveUserSession(kioskId, window.location.href, countryName);
			}
			localStorage.setItem('userSessionNavigation', currentNav.join(','));
			this.setState({ lastUrl: window.location.href });
		}

		clearInterval(this.userSessionTimer);

		if (localStorage.getItem('userSessionNavigation') !== null) {
			this.userSessionTimer = setInterval(() => {
				clearInterval(this.userSessionTimer);
				if (localStorage.getItem('userSessionActive') === 'true') {
					localStorage.setItem('userSessionNavigation', ''); // reset for next session
					localStorage.setItem('userSessionActive', 'false'); // reset for next session
					localStorage.setItem('userSessionId', ''); // reset for next session
					localStorage.setItem('selectedLanguage', defaultLanguage); // reset default Language
					this.setState({ selectedLanguage: defaultLanguage }, () => {
						history.push({ pathname: '/', state: { reload: true } });
					});
				}
			}, USER_SESSION_TIMEOUT);
		}
	}

	invertColourInactivity() {
		clearInterval(this.invertColourTimer);
		this.invertColourTimer = setInterval(() => {
			const el = document.getElementById('root'); // wayfinderAppWrapper
			if (window.location.pathname === '/') {
				el.classList.remove('normal-color');
				el.classList.add('invert-color');
				setTimeout(() => {
					el.classList.remove('invert-color');
					el.classList.add('normal-color');
				}, 2000);
			}
		}, COLOUR_INVERT_TIMER);
	}

	changeLanguage(lang) {
		localStorage.setItem('selectedLanguage', lang);
		this.setState({ selectedLanguage: lang });
	}

	render() {
		const { background, kioskDetails, selectedLanguage } = this.state;
		const backgroundBase64 = (background.length > 0) && `url(data:image/png;base64,${background})`;

		return (
			<IntlProvider locale={selectedLanguage} messages={messages[selectedLanguage]}>
				<AccessibilityProvider>
					<div id="wayfinderAppWrapper" className="App" style={{ backgroundImage: `${backgroundBase64}` }}>
						<Header language={selectedLanguage} kioskDetails={kioskDetails} />
						<Root updateLanguage={this.changeLanguage} language={selectedLanguage} />
						<Footer updateLanguage={this.changeLanguage} kioskDetails={kioskDetails} />
					</div>
				</AccessibilityProvider>
			</IntlProvider>
		);
	}
}

export default withRouter(App);

App.defaultProps = {
	history: {},
};
App.propTypes = {
	history: PropTypes.shape({ push: PropTypes.func }),
};
