import React from 'react';
import PropTypes from 'prop-types';
import WebIcon from './WebIcon';

/**
 * Child icon
 */
function StackWebIcon(props) {
	const {
		backClasses,
		backSize,
		frontClasses,
		frontSize,
		backIcon,
		frontIcon,
		id,
	} = props;
	const backClass = (backClasses) ? ` ${backClasses}` : '';
	const frontClass = (frontClasses) ? ` ${frontClasses}` : '';

	const backIconSize = (backSize === 'large') ? 'fa-stack-2x' : 'fa-stack-1x';
	const frontIconSize = (frontSize === 'large') ? 'fa-stack-2x' : 'fa-stack-1x';
	return (
		<span id={id} className="fa-stack fa-lg">
			<WebIcon icon={backIcon} className={backIconSize + backClass} />
			<WebIcon icon={frontIcon} className={frontIconSize + frontClass} />
		</span>
	);
}

export default StackWebIcon;

StackWebIcon.defaultProps = {
	backClasses: '',
	frontClasses: '',
	backSize: '',
	frontSize: '',
	id: '',
};

StackWebIcon.propTypes = {
	backClasses: PropTypes.string,
	backIcon: PropTypes.string.isRequired,
	backSize: PropTypes.string,
	frontClasses: PropTypes.string,
	frontIcon: PropTypes.string.isRequired,
	frontSize: PropTypes.string,
	id: PropTypes.string,
};
