import React from 'react';
import PropTypes from 'prop-types';

/**
 * Master icon, used for extension
 */
function Icon(props) {
	const {
		className,
		children,
		id,
		name,
		type,
	} = props;
	const classes = (className) ? ` ${className}` : '';
	return (
		<i id={id} className={`${type} ${name}${classes}`}>{children}</i>
	);
}

export default Icon;

Icon.defaultProps = {
	children: '',
	className: '',
	id: '',
};

Icon.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
};
