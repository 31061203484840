import React from 'react';
import PropTypes from 'prop-types';
import Heading from './Heading';

/**
 * Child header, used for headings
 */
function H4(props) {
	const { children, className, id } = props;
	return (
		<Heading type="h4" className={className} id={id}>{children}</Heading>
	);
}

export default H4;

H4.defaultProps = {
	className: '',
	id: '',
};

H4.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	id: PropTypes.string,
};
