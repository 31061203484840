import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { buildComponents } from '../../utils/appFunctions';
import { getKioskDetails } from '../../services/apiFunctions';
import body from '../../assets/build-code.json';
import ComponentContainer from '../reuseable/layouts/ComponentContainer';

class Template extends Component {
	constructor(props) {
		super(props);
		const defaultJson = (localStorage.getItem('json_structure') !== null) ? JSON.parse(localStorage.getItem('json_structure')) : body;

		this.state = {
			components: [],
			modules: [],
			kioskDetails: { json_structure: defaultJson },
		};

		this.fetchKioskDetails = this.fetchKioskDetails.bind(this);
		this.getPageModules = this.getPageModules.bind(this);
		this.updateLanguage = this.updateLanguage.bind(this);
	}

	componentDidMount() {
		const { pageName } = this.props;
		this.fetchKioskDetails();
		if (typeof (pageName) === 'string' && pageName.length > 0) {
			// this.pageDetails = getPageDetails(this.props.pageName);
			this.getPageModules(pageName);
		}
	}

	fetchKioskDetails = async () => {
		try {
			const result = await getKioskDetails();
			if (result !== null && Object.keys(result).length > 0) {
				// set JSON, should be a string from the db
				if (result.json_structure.length > 0) {
					result.json_structure = JSON.parse(result.json_structure);
				} else {
					result.json_structure = body;
				}
				// check for a new json structure
				const local = JSON.parse(localStorage.getItem('json_structure'));
				if (!_.isEqual(result.json_structure, local)) {
					// if found set and reload page
					localStorage.setItem('json_structure', JSON.stringify(result.json_structure));
					window.location.reload();
				}

				this.setState({ kioskDetails: result });
			}
		} catch (error) {
			// console.log('Some error occurred');
		}
	}

	getPageModules = async (title) => {
		try {
			const result = await buildComponents(title);
			this.setState(result);
		} catch (error) {
			// console.log('Some error occurred getting page modules');
		}
	}

	updateLanguage(lang) {
		const { updateLanguage } = this.props;
		updateLanguage(lang);
	}

	render() {
		const { components, kioskDetails, modules } = this.state;
		const { language, pageName } = this.props;
		let countTop = 0; // height position for modules
		return (
			<div id="templatePageWrapper">
				{
					_.map(modules, (m, i) => {
						if (typeof (components) === 'object' && typeof (components[m.key]) !== 'undefined') {
							const PageComponent = components[m.key];
							const top = (m.top) ? m.top : countTop;
							const left = (m.left) ? m.left : 0;
							const name = (typeof (m.name) === 'undefined') ? '' : m.name;
							countTop += parseInt(m.height, 10);
							if (m.key === 'Html') {
								return (
									<ComponentContainer
										key={i}
										id={m.key}
										width={m.width}
										height={m.height}
										top={top}
										left={left}
									>
										<PageComponent
											key={i}
											moduleKey={m.key}
											width={m.width}
											height={m.height}
											top={top}
											left={left}
											name={name}
											html={this.pageHtml}
											ageName={pageName}
											kioskDetails={kioskDetails}
										/>
									</ComponentContainer>
								);
							}
							return (
								<ComponentContainer
									key={i}
									id={m.key}
									width={m.width}
									height={m.height}
									top={top}
									left={left}
								>
									<PageComponent
										key={i}
										moduleKey={m.key}
										width={m.width}
										height={m.height}
										top={top}
										left={left}
										name={name}
										pageName={pageName}
										kioskDetails={kioskDetails}
										updateLanguage={this.updateLanguage}
										language={language}
									/>
								</ComponentContainer>
							);
						}
						return false;
					})
				}
			</div>
		);
	}
}

export default Template;

Template.defaultProps = {
	language: '',
	pageName: '',
};
Template.propTypes = {
	language: PropTypes.string,
	updateLanguage: PropTypes.func.isRequired,
	pageName: PropTypes.string,
};
