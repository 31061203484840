import React from 'react';

const helpers = {
	OutputTemp: (temp) => {
		if (typeof temp === 'undefined') {
			return ('');
		}
		// eslint-disable-next-line react/destructuring-assignment
		const tempCSplit = temp.split('C');
		const tempFSplit = tempCSplit[1].split('F');
		// \u00b0 is the same as &deg;
		return (
			<>
				{`${tempCSplit[0]}\u00b0C`}
				{`${tempFSplit[0]}\u00b0F`}
				{tempFSplit[1]}
			</>
		);
	},
};

export default helpers;
