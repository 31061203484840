import React from 'react';

/**
 * Renders a clear tag that is used in many components
 */
function Clear() {
	return <div className="clear" />;
}

export default Clear;
