import React from 'react';
import StackWebIcon from '../icons/StackWebIcon';

/**
 * Error message to the user when the maps cannot be loaded properly
 */
function ShowMapError() {
	return (
		<div id="googleMapFailMessage" className="display-table">
			<div className="display-cell text-center">
				<div className="googleMapFailIconWrapper" onClick={() => { window.location.reload(); }} role="button" tabIndex="0">
					<StackWebIcon id="googleMapFailIcon" backIcon="map" backClasses="black-color" frontIcon="ban" frontSize="large" frontClasses="text-danger" />
					<br />
					{'Maps are currently not available at this time'}
					<br />
					{'Touch here to retry'}
				</div>
			</div>
		</div>
	);
}

export default ShowMapError;
