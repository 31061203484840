import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Grid from './reuseable/Grid';
import PasswordButton from './reuseable/buttons/PasswordButton';

/**
 * Creates a button list
 */
class PasswordCalculator extends React.Component {
	constructor(props) {
		super(props);

		const { showNumber: showNumberProp } = props;

		this.state = {
			code: [],
			passwordFail: false,
			showNumber: showNumberProp,
			failMessage: 'Wrong Passcode',
		};

		this.addNumber = this.addNumber.bind(this);
		this.clearCode = this.clearCode.bind(this);
		this.submitCode = this.submitCode.bind(this);
		this.updateState = this.updateState.bind(this);
	}

	/**
	 * Checks authorisation
	 */
	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			const { passwordFail } = this.props;
			this.updateState({
				code: [],
				passwordFail,
			});
		}
	}

	/**
	 * Add number to state
	 * @param {void}
	 */
	addNumber(num) {
		const { code } = this.state;
		code.push(num);
		this.setState({
			code,
			passwordFail: false,
		});
	}

	/**
	 * Reset code
	 */
	clearCode() {
		this.setState({
			code: [],
		});
	}

	submitCode() {
		const { userCode } = this.props;
		const { code } = this.state;
		userCode(Number(code.join('')));
	}

	updateState(obj) {
		this.setState(obj);
	}

	/**
	 * Renders out password form
	 * @return {Component}
	 */
	render() {
		const {
			code,
			failMessage,
			passwordFail,
			showNumber,
		} = this.state;
		const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9];
		return (
			<div className="col-sm-12">
				<Grid tablet={12} classes="margb30">
					<div className="col-sm-12 code-screen">
						{
							(passwordFail) && failMessage
						}
						{
							code.map((num) => ((showNumber) ? num : '*'))
						}
					</div>
				</Grid>

				{
					_.map(buttons, (id, i) => (<PasswordButton key={`passwordbutton${i}`} id={`pwbtn${id}`} name={id} value={id} handleClick={(e) => this.addNumber(e)} />))
				}

				<PasswordButton className="clear" id="pwclear" value="clear" name="Clear" handleClick={this.clearCode} />
				<PasswordButton id="pwbtn0" name="0" value="0" handleClick={(e) => this.addNumber(e)} />
				<PasswordButton className="enter" id="pwenter" name="Enter" value="enter" handleClick={this.submitCode} />
			</div>
		);
	}
}

export default PasswordCalculator;

PasswordCalculator.defaultProps = { passwordFail: false, showNumber: false };
PasswordCalculator.propTypes = {
	passwordFail: PropTypes.bool,
	showNumber: PropTypes.bool,
	userCode: PropTypes.func.isRequired,
};
