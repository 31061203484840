import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

/**
 * Child icon, multiple icons set by the icon prop
 */
function WebIcon(props) {
	const { className, icon, id } = props;
	let iconName = '';
	switch (icon) {
	case 'tick-circle':
		iconName = 'fa-check-circle';
		break;
	case 'tick':
		iconName = 'fa-check';
		break;
	case 'map-pointer':
		iconName = 'fa-map-marker';
		break;
	case 'loading':
		iconName = 'fa-spinner fa-spin';
		break;
	case 'left-arrow':
		iconName = 'fa-angle-left';
		break;
	case 'dbl-left-arrow':
		iconName = 'fa-angle-double-left';
		break;
	case 'warning-circle':
		iconName = 'fa-exclamation-circle';
		break;
	default:
		iconName = `fa-${icon}`;
		break;
	}

	return (
		<Icon type="fa" id={id} name={iconName} className={className} />
	);
}

export default WebIcon;

WebIcon.defaultProps = {
	className: '',
	id: '',
};

WebIcon.propTypes = {
	className: PropTypes.string,
	icon: PropTypes.string.isRequired,
	id: PropTypes.string,
};
