import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import he from 'he'; // html entities
import ReactHtmlParser from 'react-html-parser';
import { MainHeading } from '../reuseable/Headings';
import Grid from '../reuseable/Grid';
import ErrorAlert from '../reuseable/ErrorAlert';
import { getFromApi } from '../../services/apiFunctions';
import SecondaryButton from '../reuseable/buttons/SecondaryButton';

class TermsConditions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			html: '',
			loadFail: false,
		};

		this.getHtml = this.getHtml.bind(this);
	}

	componentDidMount() {
		this.getHtml();
	}

	getHtml = async () => {
		try {
			const response = await getFromApi('getpagedetails/terms');
			if (response.result === true) {
				this.setState({ html: he.decode(response.html), loadFail: false });
			}
		} catch (error) {
			this.setState({ loadFail: true });
		}
	}

	render() {
		const {
			loadFail,
			html,
		} = this.state;

		return (
			<Grid tablet={12}>
				<MainHeading id="TermsConditionsTitle"><FormattedMessage id="component.termsconditions.title" defaultMessage="Terms & Conditions" /></MainHeading>
				<div className="text-container">
					<div>
						{
							(loadFail === true)
								? (<ErrorAlert><FormattedMessage id="component.termsconditions.no-content-error" defaultMessage="Terms and Conditions cannot be displayed, please refer to notice on this kiosk" /></ErrorAlert>)
								: (<div>{ ReactHtmlParser(html) }</div>)
						}
					</div>
				</div>

				<p className="text-center">
					<SecondaryButton id="moduleSingleLink" link="/menu"><FormattedMessage id="component.backtomenu.back-to-menu" defaultMessage="Back To Menu" /></SecondaryButton>
				</p>
			</Grid>
		);
	}
}

export default TermsConditions;
