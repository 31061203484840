import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { COMMISSION_ACCESS_CODE } from '../../../utils/constants';
import PasswordCalculator from '../../PasswordCalculator';
import { MainHeading } from '../../reuseable/Headings';

const HeadingContentSpacing = styled.p`
  margin-bottom: 3em;
`;

class CommissionLogin extends Component {
	constructor(props) {
		super(props);

		this.state = {
			passwordFail: false,
		};

		this.checkAccessCode = this.checkAccessCode.bind(this);
	}

	checkAccessCode(code) {
		const { successCode } = this.props;
		if (code === COMMISSION_ACCESS_CODE) {
			this.setState({ passwordFail: false });
			successCode({ enteredCode: code });
		} else {
			this.setState({ passwordFail: true });
		}
	}

	render() {
		const { passwordFail } = this.state;
		return (
			<div className="commissionLoginContainer">
				<MainHeading id="kioskComTitle">Kiosk Commissioning Login Screen</MainHeading>
				<HeadingContentSpacing>
					{'Please enter the commissioning access code to monitor the kiosk setup details'}
				</HeadingContentSpacing>
				<PasswordCalculator userCode={this.checkAccessCode} passwordFail={passwordFail} />
			</div>
		);
	}
}

export default CommissionLogin;

CommissionLogin.propTypes = {
	successCode: PropTypes.func.isRequired,
};
