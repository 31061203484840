import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PopupModal from '../PopupModal';

/**
 * Child component, used for the commission reset modal.
 */
class CommissionReset extends Component {
	static commissionResetMessage() {
		return (
			<p>
				{'Resetting will decommission this kiosk. Once this has been done it needs to be recommissioned otherwise it will not function properly.'}
				<br />
				<br />
				{'If you want to reset the kiosk press \'Yes\', otherwise press \'No\''}
			</p>
		);
	}

	render() {
		const { handleCancel, handleConfirm, show } = this.props;
		return (
			<PopupModal
				show={show}
				cancel={handleCancel}
				confirm={handleConfirm}
				cancelName="No"
				cancelNameID="No"
				confirmName="Yes"
				confirmNameID="Yes"
				title="Reset Kiosk"
				message={CommissionReset.commissionResetMessage()}
				type="danger"
			/>
		);
	}
}

export default CommissionReset;

CommissionReset.propTypes = {
	handleCancel: PropTypes.func.isRequired,
	handleConfirm: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
};
