import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { API_SERVER, APP_VERSION } from '../../../utils/constants';
import GoogleMapComponent from '../../GoogleMapComponent';
import SecondaryButton from '../../reuseable/buttons/SecondaryButton';
import InfoAlert from '../../reuseable/InfoAlert';
import Clear from '../../reuseable/Clear';
import KioskLeftTableDetails from '../../reuseable/tables/KioskLeftTableDetails';
import KioskRightTableDetails from '../../reuseable/tables/KioskRightTableDetails';
import LargeLoading from '../../reuseable/loading/LargeLoading';
import WebIcon from '../../reuseable/icons/WebIcon';

class CommissionKioskDetails extends Component {
	static updateRotation({ target }) {
		localStorage.setItem('tempRotation', parseInt(target.value, 10));
	}

	constructor(props) {
		super(props);

		this.state = {
			kioskDetails: (typeof props.kioskDetails === 'object' && Object.keys(props.kioskDetails).length > 0) ? props.kioskDetails : {},
			position: (typeof props.position === 'object' && Object.keys(props.position).length > 0) ? props.position : {},
			rotation: (typeof props.kioskDetails === 'object' && props.kioskDetails.rot > 0) ? props.kioskDetails.rot : 0,
			changeRotation: false,
			markerDrag: false,
		};

		this.updatePosition = this.updatePosition.bind(this);
		this.editableMap = this.editableMap.bind(this);
		// this.updateRotation = this.updateRotation.bind(this);

		const { rotation } = this.state;
		localStorage.setItem('tempRotation', rotation);
	}

	componentWillUnmount() {
		localStorage.removeItem('tempPosition');
		localStorage.removeItem('tempRotation');
	}

	editableMap() {
		this.setState({ markerDrag: true });
	}

	updatePosition(lat, lng) {
		const { kioskDetails, rotation } = this.state;
		const { updateState } = this.props;
		const payload = {
			kid: kioskDetails.kiosk_id,
			lat,
			lon: lng,
			rot: rotation,
		};

		fetch(`${API_SERVER}updateKioskLocation`, {
			method: 'POST',
			body: JSON.stringify(payload),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		})
			.then((res) => res.json())
			.then(async (data) => {
				if (data.result === true) {
					await fetch(`${API_SERVER}updatekioskposition`, {
						method: 'POST',
						body: JSON.stringify(payload),
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json',
						},
					})
						.then((res) => res.json())
						.then((data2) => {
							// let data = res.json();
							if (data2 === true) {
								// update kiosk details
								const details = kioskDetails;
								details.lat = payload.lat;
								details.lon = payload.lon;
								details.rot = payload.rot;
								// save locally
								this.setState({
									kioskDetails: details,
									position: { lat: details.lat, lng: details.lon },
									rotation: details.rot,
								});
								// update parent @TODO pull this out to it's own class
								updateState({
									kioskDetails: details,
									position: { lat: details.lat, lng: details.lon },
									rotation: details.rot,
								});
							}
						});
				}
			});
	}

	render() {
		const {
			changeRotation,
			kioskDetails,
			markerDrag,
			position,
		} = this.state;
		return (
			<div className="commissionKioskContainer">
				{
					(Object.keys(kioskDetails).length > 0) ? (
						<div id="kioskDetails">
							<KioskLeftTableDetails
								data={[
									[{ value: <b>Kiosk ID</b> }, { value: kioskDetails.kiosk_id }],
									[{ value: <b>Unit ID</b> }, { value: kioskDetails.unit_id }],
									[{ value: <b>Address</b> }, { value: kioskDetails.addr1 }],
									[{ value: <b>Postcode</b> }, { value: kioskDetails.postcode }],
									[{ value: <b>District No.</b> }, { value: kioskDetails.district_no }],
									[{ value: <b>Latitude</b> }, { value: kioskDetails.lat }],
									[{ value: <b>Longitude</b> }, { value: kioskDetails.lon }],
								]}
							/>
							<KioskRightTableDetails data={[
								[{ value: <b>Oasis ID</b> }, { value: kioskDetails.oasis_id }],
								[{ value: <b>Site No.</b> }, { value: kioskDetails.site_no }],
								[{ value: <b>Position</b> }, { value: kioskDetails.pos }],
								[{ value: <b>Planning Authority</b> }, { value: kioskDetails.planning_auth }],
								[{ value: <b>Orientation</b> }, { value: kioskDetails.rot }],
								[{ value: <b>WIFI Enabled</b> }, { value: (kioskDetails.wifi) ? 'Yes' : 'No' }],
								[{ value: <b>Software Version</b> }, { value: APP_VERSION }],
							]}
							/>
							<Clear />

							{
								(Object.keys(position).length > 0) ? (
									<div>
										{
											(markerDrag) && (
												<InfoAlert classes="reposition-message text-center">
													<WebIcon icon="info-circle" />
													{' You can now move the red marker on the map to re-position the kiosk to it\'s exact location.'}
													<br />
													{'When you move the marker it will automatically save it\'s new location'}
												</InfoAlert>
											)
										}
										{
											(changeRotation) && <div><input type="range" className="custom-range" name="rotationChange" id="rotationChange" min="0" max="360" value={localStorage.getItem('tempRotation')} onChange={CommissionKioskDetails.updateRotation} ref={(input) => { this.nameInputRef = input; }} /></div>
										}
										<div id="mapContainer">
											<GoogleMapComponent
												showArrowMarker
												isMarkerShown={false}
												defaultCenter={position}
												show5MinuteRing={false}
												show10MinuteRing={false}
												mapDraggable={false}
												markerDraggable={markerDrag}
												zoom={19}
												canZoom={false}
												updatePosition={(lat, lng) => this.updatePosition(lat, lng)}
											/>
											{
												(markerDrag) ? (
													<span id="commissionEditMap" className="edit-map-button selected"><WebIcon icon="tick" /></span>
												)
													: (
														<SecondaryButton id="commissionEditMap" className="edit-map-button" handleClick={() => this.editableMap()}>Edit Map Location</SecondaryButton>
													)
											}
										</div>
									</div>
								)
									: (null)
							}
							<Clear />
						</div>
					)
						: (<LargeLoading text="Loading Kiosk Details" />)
				}
			</div>
		);
	}
}

export default CommissionKioskDetails;

CommissionKioskDetails.defaultProps = {
	kioskDetails: {},
	position: {},
};
CommissionKioskDetails.propTypes = {
	kioskDetails: PropTypes.shape({
		kiosk_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		unit_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		rot: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	}),
	position: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	updateState: PropTypes.func.isRequired,
};
