import React from 'react';
import PropTypes from 'prop-types';
import AccessibilityContext from './accessibility-context';

class AccessibilityProvider extends React.Component {
	constructor() {
		super();
		// initial state
		this.state = {
			wheelchair: false,
		};
	}

	render() {
		const { children } = this.props;
		const { wheelchair } = this.state;
		return (
			<AccessibilityContext.Provider
				value={{
					wheelchair,
					toggleWheelchair: () => {
						this.setState({ wheelchair: !wheelchair });
					},
				}}
			>
				{children}
			</AccessibilityContext.Provider>
		);
	}
}

export default AccessibilityProvider;

AccessibilityProvider.defaultProps = { children: '' };
AccessibilityProvider.propTypes = {
	children: PropTypes.node,
};
