import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../Grid';

/**
 * Child button, used on commission form
 */
function PasswordButton(props) {
	const {
		className,
		handleClick,
		id,
		name,
		value,
	} = props;
	return (
		<Grid mobile={4} classes="margb30">
			<div className={`col-sm-12 password-btn ${className}`} id={id} onClick={() => handleClick(value)} role="button" tabIndex="0">
				{name}
			</div>
		</Grid>
	);
}

export default PasswordButton;

PasswordButton.defaultProps = {
	className: '',
};
PasswordButton.propTypes = {
	className: PropTypes.string,
	handleClick: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired,
	name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
