import React from 'react';
import StackWebIcon from '../icons/StackWebIcon';

/**
 * Error message to the user when the maps timeout after loading
 */
function ShowMapTimeout() {
	return (
		<div id="googleMapFailMessage" className="display-table">
			<div className="display-cell text-center">
				<div className="googleMapFailIconWrapper">
					<StackWebIcon id="googleMapFailIcon" backIcon="map" backClasses="black-color" frontIcon="ban" frontSize="large" frontClasses="text-danger" />
					<br />
					{'Maps are currently not available at this time, please try again later'}
				</div>
			</div>
		</div>
	);
}

export default ShowMapTimeout;
