import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Table from '../Table';

const KioskTableWithMargin = styled(Table)`
  margin-right: 2%;
`;

/**
 * Child component to show kiosk table
 */
function KioskLeftTableDetails(props) {
	const { classes, data, headers } = props;
	return (
		<KioskTableWithMargin classes={`kiosk-details ${classes}`} data={data} headers={headers} />
	);
}

export default KioskLeftTableDetails;

KioskLeftTableDetails.defaultProps = {
	classes: '',
	headers: [],
};

KioskLeftTableDetails.propTypes = {
	classes: PropTypes.string,
	data: PropTypes.arrayOf(PropTypes.array).isRequired,
	headers: PropTypes.arrayOf(PropTypes.array),
};
