// const localURL = window.location.href.split('/').slice(1)[0];
const localURL = `${window.location.protocol}//${window.location.href.split('/').slice(2)[0]}`;

export const USER_SESSION_TIMEOUT = '60000'; // timeout session for the users session, timeout will reset the kiosk to the landing page
export const COLOUR_INVERT_TIMER = '120000';
export const COMMISSION_ACCESS_TIME = '5';// in seconds
export const COMMISSION_BACKEND_TIMEOUT = '200000';
export const COUNCIL_MESSAGE_TIMEOUT = '30000';
export const COUNCIL_VISIBLE_TIMEOUT = '10000';
export const SITE_URL = `${window.location.protocol}//${window.location.host}`;
export const API_URL = localURL;

// Default ENV Variables
let applicationStaging = process.env.REACT_APP_APPLICATION_STAGING;
let applicationPort = process.env.REACT_APP_APPLICATION_API_PORT;
let applicationApiUrl = process.env.REACT_APP_MAPSLIVE_API;
let applicationVersion = process.env.REACT_APP_VERSION;

// Custom ENV overide
if (typeof window.customVariables === 'object') {
	if (window.customVariables.REACT_APP_APPLICATION_STAGING.length > 0) {
		applicationStaging = window.customVariables.REACT_APP_APPLICATION_STAGING;
	}
	if (String(window.customVariables.APPLICATION_API_PORT).length > 0) {
		applicationPort = window.customVariables.APPLICATION_API_PORT;
	}
	if (window.customVariables.MAPSLIVE_API.length > 0) {
		applicationApiUrl = window.customVariables.MAPSLIVE_API;
	}
	if (window.customVariables.hasOwnProperty('REACT_APP_VERSION') && window.customVariables.REACT_APP_VERSION.length > 0) {
		applicationVersion = window.customVariables.REACT_APP_VERSION;
	}
}

let apiServerUrl = `http://localhost:${applicationPort}/api/`;
let commInputCode = 2813;
if (applicationStaging === 'uat') {
	// test sites or staging env is uat
	apiServerUrl = `${localURL}/api/`;
	commInputCode = 1111;
}

if (applicationApiUrl !== undefined && applicationApiUrl.length > 0) {
	apiServerUrl = `${applicationApiUrl}/`;
}

export const API_SERVER = apiServerUrl; // 'https://wayfinder.ccuk.io/api/';
export const COMMISSION_ACCESS_CODE = commInputCode;
export const APP_VERSION = applicationVersion;

export const WEATHER_ICONS = {
	default: 'wi-day-rain-mix',
	'clear sky': 'wi-day-sunny',
	drizzle: 'wi-sprinkle',
	fog: 'wi-fog',
	'grey cloud': 'wi-cloudy',
	'hail shower': 'wi-day-showers',
	'hail showers': 'wi-day-showers',
	'heavy rain shower': 'wi-rain',
	'heavy rain showers': 'wi-rain',
	'heavy rain': 'wi-rain',
	'heavy snow': 'wi-snow',
	'Heavy Snow Showers': 'wi-day-snow-wind',
	'light cloud': 'wi-day-cloudy',
	'light rain shower': 'wi-day-rain',
	'light rain showers': 'wi-day-rain',
	'light rain': 'wi-day-rain',
	'light snow': 'wi-snow',
	mist: 'wi-day-haze',
	'partly cloudy': 'wi-day-sunny-overcast',
	sleet: 'wi-sleet',
	'sleet showers': 'wi-day-sleet',
	snow: 'wi-snow',
	snowy: 'wi-snow',
	'sunny intervals 2': 'wi-day-cloudy',
	'sunny intervals': 'wi-day-cloudy',
	sunny: 'wi-day-sunny',
	'thick cloud': 'wi-cloudy',
	thunderstorm: 'wi-thunderstorm',
	'thundery shower': 'wi-thunderstorm',
	'thundery showers': 'wi-thunderstorm',
	'white cloud': 'wi-cloudy',
};
